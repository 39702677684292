import { r } from './useApi';
import { keeper } from './utils';
import { normalizeBySchema } from './useDataModels';

export const listUsers = async (filter = {}) => {
    const res = await r({
        endpoint: `${keeper('apiUrl')}/v1/user`,
        sessionToken: keeper('sessionToken'),
        method: 'get',
        data: filter,
    });
    //console.log('useUsers.js: listUsers', res);
    return res;
};

export const getUser = async (userId) => {
    let res = {},
        searchEndpoint = '/v1/user';

    if (userId !== '0') {
        res =
            (await r({
                endpoint: `${keeper('apiUrl')}/v1/user/${userId}`,
                sessionToken: keeper('sessionToken'),
                method: 'get',
            })) || {};
    }

    if (Object.keys(res).length === 0) {
        res = await normalizeBySchema(searchEndpoint, 'post', res);
    }

    if (!res.role || typeof res.role !== 'string') {
        res.role = 'NONE';
    }
    if (!res.id || typeof res.id !== 'string') {
        res.id = '0';
    }
    //console.log('useUsers.js: getUser', res);

    return res;
};

export const saveUser = async (userId, data, saving = {}) => {
    let method = 'put',
        endpoint = `${keeper('apiUrl')}/v1/user/${userId}`,
        searchEndpoint = '/v1/user/{userId}',
        defaultTypeName = 'UpdateUserRequest';

    if (userId === '0') {
        method = 'post';
        searchEndpoint = '/v1/user';
        endpoint = `${keeper('apiUrl')}/v1/user`;
        defaultTypeName = 'UpdateUserRequest';
    }

    const dataModel = await normalizeBySchema(searchEndpoint, method, data);
    dataModel.typeName =
        !dataModel.typeName || dataModel.typeName.length === 0
            ? defaultTypeName
            : dataModel.typeName;

    if (!('password' in data) || data.password.length === 0) {
        delete dataModel.password;
    }

    const res = await r({
        endpoint: endpoint,
        sessionToken: keeper('sessionToken'),
        method: method,
        data: dataModel,
    });

    //console.log('useUsers.js: saveUser', dataModel, res);
    saving.active = false;
    return res;
};

export const patchUser = async (userId, data = {}, saving = {}) => {
    const method = 'patch',
        searchEndpoint = '/v1/user/{userId}',
        endpoint = `${keeper('apiUrl')}/v1/user/${userId}`,
        defaultTypeName = 'PatchUserRequest';

    const dataModel = await normalizeBySchema(searchEndpoint, method, data);
    dataModel.typeName =
        !dataModel.typeName || dataModel.typeName.length === 0
            ? defaultTypeName
            : dataModel.typeName;

    const res = await r({
        endpoint: endpoint,
        sessionToken: keeper('sessionToken'),
        method: method,
        data: dataModel,
    });
    console.log('useUsers.js: patch', res);
    saving.active = false;
    return res;
};

export const useUsers = () => {
    return {
        listUsers: listUsers,
        getUser: getUser,
        patchUser: patchUser,
        saveUser: saveUser,
    };
};

export default useUsers;
