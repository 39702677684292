import models from '../models/dataModels';

export const normalizeBySchema = async (endpoint, method, data) => {
    const _schema = await schema(endpoint, method);
    const _data = {};

    if (typeof data !== 'object') {
        return false;
    }

    if (typeof _schema === 'object' && Object.getOwnPropertyNames(_schema).length > 0) {
        /* console.log(
            `useDataModels.js: description ${await methodDescription(
                endpoint,
                method,
            )}`,
        ); */

        for (let key in _schema) {
            if (!(key in data) || key === 'typeName') {
                /* console.log(
                    `useDataModels.js: normalizeBySchema NOT found ${key} in data`,
                ); */
                _data[key] = _schema[key].default || '';
            } else {
                /* console.log(
                    `useDataModels.js: normalizeBySchema OK found ${key} in data`,
                ); */
                _data[key] = data[key];
            }
        }
    } else {
        return typeof data === 'object' ? data : {};
    }

    return _data;
};

export const schema = async (endpoint, method) => {
    const _model = load()[endpoint];
    let _schema = {};

    if (
        typeof _model === 'object' &&
        'methods' in _model &&
        typeof _model.methods === 'object' &&
        method in _model.methods &&
        typeof _model.methods[method] === 'object' &&
        'schema' in _model.methods[method] &&
        typeof _model.methods[method].schema === 'object'
    ) {
        _schema = _model.methods[method].schema;
    }

    //console.log('useModels.js: schema', endpoint, method, _model.methods[method]);
    return _schema;
};

export const methodDescription = async (endpoint, method) => {
    const _model = load()[endpoint];
    let description = '';

    if (
        typeof _model === 'object' &&
        'methods' in _model &&
        typeof _model.methods === 'object' &&
        method in _model.methods &&
        typeof _model.methods[method] === 'object' &&
        'description' in _model.methods[method] &&
        typeof _model.methods[method].description === 'string'
    ) {
        description = _model.methods[method].description;
    }

    return description;
};

export const load = () => {
    const m = models() || {};
    //console.log('useDataModels.js: load models', m);
    return m;
};

export const useModels = async () => {
    return {
        normalizeBySchema: normalizeBySchema,
        methodDescription: methodDescription,
        schema: schema,
        load: load,
    };
};

export default useModels;
