<template>
    <Headify :data="headMeta" />

    <!-- MAIN CONTAINER - you should never seee the yellow background!!!! -->
    <div class="w-full h-screen min-h-screen bg-yellow-100">
        <!-- TOP NAV SOMETHING -->
        <!-- <header class="fixed top-0 right-0 bg-slate-900 h-16 w-screen z-1000">
            <div class="mx-auto flex h-16 items-center justify-between px-4 sm:px-6 lg:px-8">
                <MdiHome class="h-8 w-8 text-white" aria-hidden="true" />
                <div class="flex items-center gap-x-8">
                    <button type="button" class="-m-2.5 p-2.5 text-gray-400 hover:text-gray-300">
                        <MdiInformation class="h-6 w-6" aria-hidden="true" />
                    </button>
                    <a href="#" class="-m-1.5 p-1.5">
                        <MdiAccount class="h-8 w-8 text-white bg-gray-800" />
                    </a>
                </div>
            </div>
        </header> -->

        <!-- CONTENT WRAPPER, natural scroll - browser default behavior -->
        <div class="w-full py-16 bg-white min-h-screen">
            <!-- <NavMain :key="route.name" /> -->
            <!-- CONTENT - whatever you want -->
            <div class="w-full mx-auto max-w-4xl p-4">
                <h1 class="px-2 py-4 text-4xl font-medium text-blue-600">
                    {{ t('UITest') }}
                </h1>
                <div class="text-center">
                    <div class="mx-auto flex gap-4 justify-center max-w-lg">
                        <button
                            :class="
                                theme.f('btn', {
                                    state: 'primary',
                                    disabled: false,
                                })
                            "
                            @click="remote.leftOpen = !remote.leftOpen"
                        >
                            <MdiCog :class="theme.f('btnIcon')" />
                            LEFT
                        </button>
                        <button
                            :class="
                                theme.f('btn', {
                                    state: 'primary',
                                    disabled: false,
                                })
                            "
                            @click="remote.rightOpen = !remote.rightOpen"
                        >
                            <MdiCog :class="theme.f('btnIcon')" />
                            RIGHT
                        </button>
                    </div>
                    <div class="max-w-5xl mx-auto w-full space-y-6">
                        <div class="text-left">
                            <h2 class="my-6 text-3xl font-normal text-emerald-400 p-1">Buttons</h2>
                        </div>
                        <div
                            class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 sm:gap-4 text-left"
                        >
                            <div v-for="state in Object.keys(theme.data.button.state)" :key="state">
                                <div class="p-1 text-center">
                                    <button
                                        :class="
                                            theme.f('btn', {
                                                state: state,
                                            })
                                        "
                                    >
                                        <component
                                            :is="btnConfig.icon"
                                            :class="theme.f('btnIcon')"
                                        />
                                        {{ btnConfig.text }}
                                    </button>
                                </div>
                            </div>
                            <div class="p-1 text-center">
                                <button
                                    :class="
                                        theme.f('btn', {
                                            state: 'primary',
                                            disabled: true,
                                        })
                                    "
                                >
                                    <component :is="btnConfig.icon" :class="theme.f('btnIcon')" />
                                    {{ btnConfig.text }}
                                </button>
                            </div>
                        </div>

                        <div class="text-left">
                            <h2 class="my-6 text-3xl font-normal text-emerald-400 p-1">
                                Alert & Buttons
                            </h2>
                        </div>
                        <div class="flex flex-col sm:flex-row">
                            <div class="p-1 text-left w-full">
                                <div :class="theme.f('alert', { state: 'info' })">
                                    <div class="font-medium py-1">
                                        <MdiInformation :class="theme.f('alertIcon')" />
                                        Material icons
                                    </div>
                                    <p class="py-1">7296 Material design icons.</p>
                                    <p class="py-1">
                                        Head over to our GitHub repo and suggest it. You can also
                                        contribute your idea if you're feeling creative.
                                    </p>
                                    <div class="flex gap-2 justify-end items-end pt-4">
                                        <button :class="theme.f('btn')">Cancel</button>
                                        <button
                                            :class="
                                                theme.f('btn', {
                                                    state: 'info',
                                                })
                                            "
                                        >
                                            <component
                                                :is="btnConfig.icon"
                                                :class="theme.f('btnIcon')"
                                            />
                                            Send
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="p-1"></div>
                        </div>
                        <div class="text-left">
                            <h2 class="my-6 text-3xl font-normal text-emerald-400 p-1">Alerts</h2>
                            <div class="w-full sm:grid sm:grid-cols-2 gap-4 my-4 p-1">
                                <div
                                    v-for="state in Object.keys(theme.data.alert.state)"
                                    :key="state"
                                    class="flex-col"
                                >
                                    <div
                                        :class="`${theme.data.alert.state[state]} flex-none font-medium my-2 p-2 capitalize before:content-['🪐_']`"
                                    >
                                        {{ state }}
                                    </div>
                                    <div>
                                        <div :class="theme.f('alert', { state: state })">
                                            <div class="font-medium py-1">
                                                <MdiInformation :class="theme.f('alertIcon')" />
                                                Material icons
                                            </div>
                                            <p class="py-1">7296 Material design icons.</p>
                                            <p class="py-1">
                                                Head over to our GitHub repo and suggest it. You can
                                                also contribute your idea if you're feeling
                                                creative.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="text-left">
                            <h2 class="my-6 text-3xl font-normal text-emerald-400 p-1">
                                SVG icons
                            </h2>
                            <p class="my-4 text-base">7296 Material design icons.</p>
                            <p class="my-4 text-base">
                                Inline icons example
                                <MdiLock class="w-4 h-4 -mt-1 inline text-indigo-600" />
                                locked account and
                                <MdiCog class="w-4 h-4 -mt-1 inline text-indigo-600" />
                                settings.
                            </p>
                        </div>

                        <div
                            class="grid grid-cols-3 gap-2 md:grid-cols-4 md:gap-4 lg:grid-cols-6 text-3xl md:text-4xl"
                        >
                            <MdiAccount
                                class="mx-auto text-blue-600 w-10 h-10 p-1 rounded border border-blue-400"
                            />
                            <MdiLock
                                class="mx-auto text-blue-600 w-10 h-10 p-1 rounded border border-blue-400"
                            />
                            <MdiFilePdfBox
                                class="mx-auto text-blue-600 w-10 h-10 p-1 rounded border border-blue-400"
                            />
                            <MdiClose
                                class="mx-auto text-blue-600 w-10 h-10 p-1 rounded border border-blue-400"
                            />
                            <MdiHome
                                class="mx-auto text-blue-600 w-10 h-10 p-1 rounded border border-blue-400"
                            />
                            <MdiMagnify
                                class="mx-auto text-blue-600 w-10 h-10 p-1 rounded border border-blue-400"
                            />
                            <MdiMenu
                                class="mx-auto text-blue-600 w-10 h-10 p-1 rounded border border-blue-400"
                            />
                            <MdiCog
                                class="mx-auto text-blue-600 w-10 h-10 p-1 rounded border border-blue-400"
                            />
                            <MdiContentSave
                                class="mx-auto text-blue-600 w-10 h-10 p-1 rounded border border-blue-400"
                            />
                            <MdiEmail
                                class="mx-auto text-blue-600 w-10 h-10 p-1 rounded border border-blue-400"
                            />
                            <MdiKeyboard
                                class="mx-auto text-blue-600 w-10 h-10 p-1 rounded border border-blue-400"
                            />
                            <MdiCloudLock
                                class="mx-auto text-blue-600 w-10 h-10 p-1 rounded border border-blue-400"
                            />
                        </div>
                    </div>
                    <div class="max-w-5xl mx-auto w-full space-y-6">
                        <div class="text-left">
                            <h2 class="my-6 text-3xl font-normal text-emerald-400 p-1">
                                SVG spinners
                            </h2>
                        </div>

                        <div
                            class="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3 md:gap-4 text-3xl md:text-4xl py-4 isolate"
                        >
                            <div>
                                <Spinner
                                    type="complex"
                                    :color="`my-4 text-blue-600`"
                                    :color2="`text-emerald-600`"
                                    :text-color="`text-blue-600`"
                                    :overlay="false"
                                    :speed="1000"
                                    :dots="true"
                                    :dot-char="'>'"
                                    :dot-dual="true"
                                    :dot-reverse="true"
                                    :text="
                                        t('loadingExt', {
                                            ext: `${percent}%`,
                                        })
                                    "
                                    :inline="true"
                                    :pulse="false"
                                />
                            </div>
                            <div>
                                <Spinner
                                    type="bars"
                                    :color="`my-4 text-blue-600`"
                                    :color2="`text-emerald-600`"
                                    :text-color="`text-blue-600`"
                                    :overlay="false"
                                    :speed="1000"
                                    :dots="false"
                                    :dot-char="'>'"
                                    :dot-dual="true"
                                    :dot-reverse="true"
                                    :text="
                                        t('sendingExt', {
                                            ext: `${percent}%`,
                                        })
                                    "
                                    :inline="true"
                                    :pulse="false"
                                />
                            </div>
                            <div>
                                <Spinner
                                    type="dots"
                                    :color="`my-4 text-blue-600`"
                                    :color2="`text-emerald-600`"
                                    :text-color="`text-blue-600`"
                                    :overlay="false"
                                    :speed="1000"
                                    :dots="true"
                                    :dot-char="'.'"
                                    :dot-dual="false"
                                    :dot-reverse="false"
                                    :text="t('loading')"
                                    :inline="true"
                                    :pulse="true"
                                />
                            </div>
                            <div>
                                <Spinner
                                    type="clock"
                                    :color="`my-4 text-blue-600`"
                                    :color2="`text-emerald-600`"
                                    :text-color="`text-blue-600`"
                                    :overlay="false"
                                    :speed="2600"
                                    :dots="true"
                                    :dot-char="'/'"
                                    :dot-dual="true"
                                    :dot-reverse="false"
                                    :text="t('loading')"
                                    :inline="true"
                                    :pulse="false"
                                />
                            </div>
                            <div>
                                <Spinner
                                    type="circle"
                                    :color="`my-4 text-blue-600`"
                                    :color2="`text-emerald-600`"
                                    :text-color="`text-blue-600`"
                                    :overlay="false"
                                    :speed="1000"
                                    :dots="true"
                                    :dot-char="'>'"
                                    :dot-dual="true"
                                    :dot-reverse="false"
                                    :text="t('loading')"
                                    :inline="true"
                                    :pulse="true"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- LEFT COLUMN - FILTER -->
    <div
        :class="[
            remote.leftOpen === true ? 'left-0' : '-left-96',
            'fixed top-0 bottom-0 w-96 z-[999] border-r border-red-400 bg-gray-200 h-screen overflow-y-hidden pb-32',
        ]"
    >
        <!-- COLUMN HEADER FIXED TO COLUMN -->
        <div class="bg-slate-700 pt-16">
            <div class="flex w-full">
                <div class="text-left text-white grow p-4">Filter</div>
                <div class="flex-none p-4">
                    <button
                        type="button"
                        class="rounded-md text-gray-100 hover:text-gray-300 focus:outline-none"
                        @click="remote.leftOpen = false"
                    >
                        <span class="sr-only">Close</span>
                        <MdiClose class="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
        <!-- COLUMN CONTENT SCROLLABLE -->
        <div class="h-full">
            <div class="block">
                
            </div>
        </div>
    </div>

    <!-- RIGHT COLUMN - PREVIEW -->
    <div
        :class="[
            remote.rightOpen === true ? 'right-0' : '-right-96',
            'fixed top-16 bottom-0 w-96 z-[999] border-l border-red-400 bg-gray-200 h-screen overflow-y-hidden pb-32',
        ]"
    >
        <!-- COLUMN HEADER FIXED TO COLUMN -->
        <div class="bg-slate-700">
            <div class="flex w-full">
                <div class="text-left text-white grow p-4">Preview</div>
                <div class="flex-none p-4">
                    <button
                        type="button"
                        class="rounded-md text-gray-100 hover:text-gray-300 focus:outline-none"
                        @click="remote.rightOpen = false"
                    >
                        <span class="sr-only">Close</span>
                        <MdiClose class="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
        <!-- COLUMN CONTENT SCROLLABLE -->
        <div class="h-full p-4 overflow-y-auto">
            <div class="block">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Class aptent taciti
                sociosqu ad litora torquent per conubia nostra, per inceptos hymenaeos. Cras pede
                libero, dapibus nec, pretium sit amet, tempor quis. Fusce consectetuer risus a nunc.
                Nunc auctor. Nulla turpis magna, cursus sit amet, suscipit a, interdum id, felis.
                Curabitur ligula sapien, pulvinar a vestibulum quis, facilisis vel sapien. Nulla
                est. Nunc dapibus tortor vel mi dapibus sollicitudin. Class aptent taciti sociosqu
                ad litora torquent per conubia nostra, per inceptos hymenaeos. Duis risus. Itaque
                earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores
                alias alias consequatur aut perferendis doloribus asperiores repellat. Sed convallis
                magna eu sem. Nunc tincidunt ante vitae massa. Duis pulvinar. In dapibus augue non
                sapien. Proin in tellus sit amet nibh dignissim sagittis. Etiam neque. Sed convallis
                magna eu sem. Mauris elementum mauris vitae tortor. Praesent vitae arcu tempor neque
                lacinia pretium. Curabitur ligula sapien, pulvinar a vestibulum quis, facilisis vel
                sapien. Curabitur bibendum justo non orci. Etiam ligula pede, sagittis quis,
                interdum ultricies, scelerisque eu. Et harum quidem rerum facilis est et expedita
                distinctio. Duis viverra diam non justo. Curabitur bibendum justo non orci. Duis
                condimentum augue id magna semper rutrum. Praesent vitae arcu tempor neque lacinia
                pretium. Nulla non arcu lacinia neque faucibus fringilla. In laoreet, magna id
                viverra tincidunt, sem odio bibendum justo, vel imperdiet sapien wisi sed libero.
                Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Praesent dapibus. Maecenas sollicitudin. Sed ut perspiciatis unde omnis iste
                natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
                eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta
                sunt explicabo. Morbi leo mi, nonummy eget tristique non, rhoncus non leo. Nullam
                sit amet magna in magna gravida vehicula. Nullam sit amet magna in magna gravida
                vehicula. vehicula. Donec iaculis gravida nulla. Aliquam erat volutpat. Proin pede
                metus, vulputate nec, fermentum fringilla, vehicula vitae, justo. Integer lacinia.
                Nulla turpis magna, cursus sit amet, suscipit a, interdum id, felis. Excepteur sint
                occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                est laborum. Curabitur sagittis hendrerit ante. Nullam faucibus mi quis velit.
                Praesent Praesent dapibus. Mauris metus. Mauris tincidunt sem sed arcu. Phasellus
                enim erat, vestibulum vel, aliquam a, posuere eu, velit. Ut enim ad minim veniam,
                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                Mauris tincidunt sem sed arcu. Cum sociis natoque penatibus et magnis dis parturient
                montes, nascetur ridiculus mus. Mauris dolor felis, sagittis at, luctus sed, aliquam
                non, tellus. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur
                ridiculus mus. Aliquam erat volutpat. Mauris suscipit, ligula sit amet pharetra
                semper, nibh ante cursus purus, vel sagittis velit mauris vel metus. Praesent in
                mauris eu tortor porttitor accumsan. Sed ac dolor sit amet purus malesuada congue.
                Fusce suscipit libero eget elit. Fusce wisi. Integer vulputate sem a nibh rutrum
                consequat. Duis bibendum, lectus ut viverra rhoncus, dolor nunc faucibus libero,
                eget facilisis enim ipsum id lacus. Aenean placerat. Fusce aliquam vestibulum ipsum.
                Integer pellentesque quam vel velit. Suspendisse sagittis ultrices augue. Integer
                pellentesque quam vel velit. Nullam rhoncus aliquam metus. Curabitur ligula sapien,
                pulvinar a vestibulum quis, facilisis vel sapien.
            </div>
        </div>
    </div>

    <ModalPrompt
        :state="confirmModal.state"
        :buttons="confirmModal.buttons"
        :icon="confirmModal.icon"
    />
</template>

<script setup>
import { reactive, ref } from 'vue';
import { useRoute } from 'vue-router';
import Headify from '../components/HeadifyComponent.vue';
import Spinner from '../components/SpinnerComponent.vue';
import navs from '../composables/navs';
import { t } from '../composables/i18n';
import ModalPrompt from '../components/ModalPromptComponent.vue';


import {
    MdiAccount,
    MdiClose,
    MdiCloudLock,
    MdiCog,
    MdiContentSave,
    MdiEmail,
    MdiFilePdfBox,
    MdiHome,
    MdiKeyboard,
    MdiLock,
    MdiMagnify,
    MdiMenu,
    MdiListStatus,
    MdiInformation,
} from 'materialdesignicons-vue3/icons/';

const percent = ref(0);
/* const speed = ref(200);

setInterval(() => {
    let _p = parseInt(percent.value) >= 100 ? 0 : parseInt(percent.value) + 1;
    percent.value = _p.toString().padStart(3, '\xa0');
}, parseInt(speed.value));
 */
const route = useRoute();

const headMeta = navs().metaFind(route.name);

const btnConfig = reactive({
    text: 'Action text',
    icon: MdiContentSave,
    click: () => {
        return true;
    },
});

const remote = reactive({
    leftOpen: false,
    rightOpen: false,
});

const confirmModal = reactive({
    state: {
        open: false,
        title: t('multipleUploadFilesSentTitle'),
        text: t('multipleUploadFilesSent'),
        data: {},
    },
    icon: MdiListStatus,
    buttons: [
        {
            title: t('buttonOk'),
            action: () => {
                confirmModal.state.open = false;
            },
            class: ' bg-green-400 hover:bg-green-500 text-white',
        },
    ],
});
</script>
