import { button } from './lib/button';
import { alert } from './lib/alert';
import { nav } from './lib/nav';
import { modal } from './lib/modal';

export const themeConfig = () => {
    const config = {
        data: {
            button,
            alert,
            nav,
            modal,
        },
        factory: {},
    };

    const mapFactory = (factory) => {
        Object.keys(factory).map((index) => {
            config.factory[index] = factory[index];
        });
    };

    Object.keys(config.data).map((i) => {
        mapFactory(config.data[i].factory);
    });

    config.f = (key, cfg = {}) => {
        const tf = config.factory[key](cfg);
        return tf;
    };

    return config;
};
