<template>
    <nav class="select-none py-2" aria-label="Filter">
        <div
            class="wrapper flex justify-end w-full p-0.5 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600"
        >
            <div :class="[tags.show ? 'w-1/2' : 'w-full', 'flex']">
                <input
                    v-show="search.show"
                    id="search"
                    v-model="search.subject"
                    type="text"
                    name="search"
                    class="block w-full rounded-md border-0 py-1.5 mr-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 focus:ring-0"
                    :placeholder="t('search')"
                    @keyup="triggerFilter()"
                    @change="triggerFilter()"
                />
                <button
                    v-show="search.show"
                    v-tippy="search.title"
                    type="button"
                    class="relative inline-flex items-center justify-end rounded-md bg-white px-2 py-2 text-gray-400 shadow-sm hover:bg-gray-200 hover:text-gray-600 ring-0"
                    @click.prevent="triggerFilter()"
                >
                    <MdiMagnify class="h-5 w-5" aria-hidden="true" />
                    <span class="sr-only">{{ search.title }}</span>
                </button>
            </div>

            <div v-if="tags.show" class="w-1/2 flex" @click.stop>
                <div class="w-full">
                    <input
                        id="tag-search"
                        v-model="tags.subject"
                        type="text"
                        name="tag-search"
                        class="w-full rounded-md border-0 py-1.5 mr-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 focus:ring-0 focus:ring-offset-0"
                        :placeholder="t('tagEnter')"
                        @blur="blur"
                        @click="filterSuggestions()"
                        @keyup="filterSuggestions()"
                    />
                    <div class="relative w-full">
                        <div
                            v-if="tagsConfig.showSuggestions"
                            class="w-full p-4 m-0 border border-solid rounded-b-md border-gray-300 z-3000 absolute top-px bg-white"
                        >
                            <div
                                v-for="(tag, index) in tagsConfig.suggested"
                                :key="index"
                                v-tippy="t('buttonAddTag')"
                                class="inline-block my-1"
                                @mousedown="addTag(tag)"
                            >
                                <span
                                    class="cursor-pointer rounded bg-blue-600 text-gray-50 hover:text-amber-200 hover:bg-blue-800 text-xs font-medium px-2 py-1.5 mr-1"
                                >
                                    {{ tag.name }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-for="(item, index) in buttons"
                v-show="item.show"
                :key="index"
                class="flex-none rounded-md border-0"
            >
                <button
                    v-tippy="item.title"
                    type="button"
                    class="relative inline-flex items-center justify-end rounded-md bg-white px-2 py-2 text-gray-400 shadow-sm hover:bg-gray-200 hover:text-gray-600 ring-0"
                    @click="item.action"
                >
                    <component :is="item.icon" class="h-5 w-5 shrink-0" aria-hidden="true" />
                    <span class="sr-only">{{ item.title }}</span>
                </button>
            </div>

            <!-- <button
                v-show="config.new.show"
                type="button"
                class="relative inline-flex items-center rounded-md bg-white px-2 py-2 text-gray-400 hover:bg-gray-50 focus:z-10"
                v-tippy="config.new.title"
                @click="config.new.action"
            >
                <MdiPlus class="h-5 w-5" aria-hidden="true" />
                <span class="sr-only">{{ config.new.title }}</span>
            </button> -->
        </div>
        <div class="flex py-2">
            <div
                v-for="tag in tagsConfig.used"
                :key="tag.tagId"
                class="flex items-center justify-center cursor-default rounded bg-blue-600 text-gray-50 text-xs font-medium px-2 py-1.5 mr-2"
            >
                <span class="-mt-1">{{ tag.name }}</span>
                <button
                    v-tippy="t('buttonRemoveTag')"
                    class="ml-2 cursor-pointer border-none text-gray-50 bg-transparent hover:text-amber-200"
                    @click.stop="removeTag(tag)"
                >
                    <MdiClose class="w-4 h-4" />
                </button>
            </div>
        </div>
    </nav>
</template>

<script setup>
import { reactive } from 'vue';
import { MdiMagnify, MdiClose } from 'materialdesignicons-vue3/icons/';
//import { t } from '../composables/i18n';

defineExpose({
    name: 'ListToolbar',
});

const props = defineProps({
    indexId: {
        type: String,
        default: 'id',
    },
    childKey: {
        type: String,
        default: '',
    },
    search: {
        type: Object,
        default: () => {
            return {
                subject: '',
                keys: ['name'],
                sub: false,
                show: false,
            };
        },
    },
    tags: {
        type: Object,
        default: () => {
            return {
                show: false,
                subject: '',
                all: [],
                selected: [],
            };
        },
    },
    buttons: {
        type: Object,
        default: () => {
            return [];
        },
    },
    data: {
        type: Object,
        required: true,
        default: () => {
            return [];
        },
    },
    result: {
        type: Object,
        required: true,
        default: () => {
            return [];
        },
    },
});

const data = props.data;
const result = props.result;
const search = props.search;
const tags = props.tags;
const buttons = props.buttons;
const indexId = props.indexId;
const childKey = props.childKey;

const tagsConfig = reactive({
    showSuggestions: false,
    suggested: [],
    used: [],
    all: tags.all,
    results: {
        fulltext: [],
        tagSearch: [],
    },
});

/*
. Dev state
*/
const blur = () => {
    tagsConfig.showSuggestions = false;
};

const filterSuggestions = () => {
    tagsConfig.showSuggestions = true;
    tagsConfig.suggested = [];
    tagsConfig.all.map((item) => {
        const src = item.name.toLowerCase(),
            search = tags.subject.toLowerCase();

        if (src.includes(search)) {
            tagsConfig.suggested.push(item);
        }
    });
};

const removeTag = async (tag) => {
    if (isUsedTag(tag)) {
        tagsConfig.used = tagsConfig.used.filter((item) => {
            return tag.tagId != item.tagId;
        });
        //console.log('REMOVE', tag.name, tagsConfig.used);
        await triggerFilter();
    }
};

const addTag = async (tag) => {
    if (!isUsedTag(tag)) {
        tagsConfig.used.push(tag);
        //console.log('ADD', tag.name, tagsConfig.used);
        await triggerFilter();
    }
};

const isUsedTag = (tag) => {
    const searchId = tagsConfig.used.find((item) => {
        return item.tagId === tag.tagId;
    });
    if (typeof searchId === 'undefined') {
        return false;
    }
    return true;
};

const isItemTag = (item, tag) => {
    const searchId = item.extension.TAGS.find((itemTag) => {
        return itemTag.tag.tagId === tag.tagId;
    });
    if (typeof searchId === 'undefined') {
        return false;
    }
    return true;
};

const itemIsIn = (item, inkey) => {
    let searchId;
    if (childKey.length > 0) {
        searchId = tagsConfig.results[inkey].find((itemIn) => {
            return itemIn[childKey][indexId] === item[childKey][indexId];
        });
    } else {
        searchId = tagsConfig.results[inkey].find((itemIn) => {
            return itemIn[indexId] === item[indexId];
        });
    }
    if (typeof searchId === 'undefined') {
        return false;
    }
    return true;
};

const triggerFilter = async () => {
    await fulltext();
    await tagSearch();
    finalSearchResult();
};
/*
. Working state
*/
const tagSearch = async () => {
    if (tagsConfig.used.length > 0) {
        tagsConfig.results.tagSearch = [];
        data.map((item) => {
            let approved = true;
            for (let i in tagsConfig.used) {
                if (!isItemTag(item, tagsConfig.used[i])) {
                    approved = false;
                }
            }
            if (approved === true) {
                tagsConfig.results.tagSearch.push(item);
            }
        });
    } else {
        tagsConfig.results.tagSearch = data;
    }
};

const fulltext = async () => {
    if (search.subject.length > 2) {
        tagsConfig.results.fulltext = [];
        data.map((item) => {
            for (let i in search.keys) {
                if (childKey.length > 0) {
                    if (
                        item[childKey][search.keys[i]]
                            .toLowerCase()
                            .includes(search.subject.toLowerCase())
                    ) {
                        tagsConfig.results.fulltext.push(item);
                    }
                } else {
                    if (item[search.keys[i]].toLowerCase().includes(search.subject.toLowerCase())) {
                        tagsConfig.results.fulltext.push(item);
                    }
                }
            }
        });
    } else {
        tagsConfig.results.fulltext = data;
    }
};

const finalSearchResult = () => {
    result.length = 0;
    data.map((item) => {
        const hide = !(itemIsIn(item, 'tagSearch') && itemIsIn(item, 'fulltext'));
        const resultItem = toggleObject(item, hide);
        if (hide === false) {
            result.push(resultItem);
        }
    });
    //console.log('Search result', result);
};

const toggleObject = (item, hide) => {
    if (childKey.length > 0) {
        item[childKey]._hide = hide;
    } else {
        item._hide = hide;
    }
    return item;
};
</script>

<style>
.slide-fade-enter-active {
    transition: all 200ms ease-out;
}

.slide-fade-leave-active {
    transition: all 200ms ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(100px);
    opacity: 0;
}
</style>
