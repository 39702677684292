const config = {
    default:
        'transition-all duration-300 select-none rounded-md border border-transparent rounded-md inline-flex gap-1 justify-center items-center outline-0 outline-none focus:outline-none focus:outline-0 font-medium min-w-fit',
    //icon: 'w-3 h-3 mr-0.5 sm:mr-1 md:w-5 md:h-5',
    icon: {
        xs: 'w-3 h-3',
        sm: 'w-4 h-4 lg:w-5 lg:h-5',
        md: 'w-5 h-5',
        lg: 'w-5 h-5 lg:w-6 lg:h-6',
        xl: 'w-6 h-6 lg:w-7 lg:h-7',
    },
    size: {
        xs: 'text-xs py-0.5 px-2',
        sm: 'text-xs py-1 px-2 sm:text-sm sm:py-1.5 sm:px-3',
        md: 'text-xs py-1 px-2 sm:text-sm sm:py-1.5 sm:px-3 md:text-base md:py-2 md:px-4',
        lg: 'text-xs py-1 px-2 sm:text-sm sm:py-1.5 sm:px-3 md:text-base md:py-2 md:px-4',
        xl: 'text-xs py-1 px-2 sm:text-sm sm:py-1.5 sm:px-3 md:text-base md:py-2 md:px-4',
    },
    state: {
        brand: {
            default:
                'bg-gradient-to-tr from-emerald-400 via-blue-500 to-indigo-600 hover:from-emerald-500 hover:via-sky-600 hover:to-blue-700 focus:ring-blue-400 text-white shadow-sm focus:shadow-sm focus:ring-2 focus:ring-offset-2',
            disabled:
                'bg-gray-200 hover:bg-gray-200 focus:ring-transparent text-gray-400 cursor-not-allowed shadow-sm focus:shadow-sm',
        },
        promo: {
            default:
                'bg-violet-500 hover:bg-violet-600 focus:ring-violet-400 text-white shadow-sm focus:shadow-sm focus:ring-2 focus:ring-offset-2',
            disabled:
                'bg-gray-200 hover:bg-gray-200 focus:ring-transparent text-gray-400 cursor-not-allowed shadow-sm focus:shadow-sm',
        },
        primary: {
            default:
                'bg-blue-500 hover:bg-blue-600 focus:ring-blue-400 text-white shadow-sm focus:shadow-sm focus:ring-2 focus:ring-offset-2',
            disabled:
                'bg-gray-200 hover:bg-gray-200 focus:ring-transparent text-gray-400 cursor-not-allowed shadow-sm focus:shadow-sm',
        },
        info: {
            default:
                'bg-cyan-500 hover:bg-cyan-600 focus:ring-cyan-400 text-white shadow-sm focus:shadow-sm focus:ring-2 focus:ring-offset-2',
            disabled:
                'bg-gray-200 hover:bg-gray-200 focus:ring-transparent text-gray-400 cursor-not-allowed shadow-sm focus:shadow-sm',
        },
        success: {
            default:
                'bg-emerald-500 hover:bg-emerald-600 focus:ring-emerald-400 text-white shadow-sm focus:shadow-sm focus:ring-2 focus:ring-offset-2',
            disabled:
                'bg-gray-200 hover:bg-gray-200 focus:ring-transparent text-gray-400 cursor-not-allowed shadow-sm focus:shadow-sm',
        },
        warning: {
            default:
                'bg-amber-500 hover:bg-amber-600 focus:ring-amber-400 text-white shadow-sm focus:shadow-sm focus:ring-2 focus:ring-offset-2',
            disabled:
                'bg-gray-200 hover:bg-gray-200 focus:ring-transparent text-gray-400 cursor-not-allowed shadow-sm focus:shadow-sm',
        },
        danger: {
            default:
                'bg-red-500 hover:bg-red-600 focus:ring-red-400 text-white shadow-sm focus:shadow-sm',
            disabled:
                'bg-gray-200 hover:bg-gray-200 focus:ring-transparent text-gray-400 cursor-not-allowed shadow-sm focus:shadow-sm focus:ring-2 focus:ring-offset-2',
        },
        neutral: {
            default:
                'bg-neutral-500 hover:bg-neutral-600 focus:ring-neutral-400 text-white shadow-sm focus:shadow-sm focus:ring-2 focus:ring-offset-2',
            disabled:
                'bg-gray-200 hover:bg-gray-200 focus:ring-transparent text-gray-400 cursor-not-allowed shadow-sm focus:shadow-sm focus:ring-2 focus:ring-offset-2',
        },
        clean: {
            default:
                'bg-white focus:ring-neutral-400 text-gray-500 hover:text-gray-800 shadow-sm focus:shadow-sm focus:ring-2 focus:ring-offset-2',
            disabled:
                'bg-white focus:ring-transparent text-gray-400 hover:text-gray-400 cursor-not-allowed  shadow-sm focus:shadow-sm',
        },
        transparent: {
            default:
                'bg-transparent ring-0 ring-transparent ring-offset-0 outline-none outline-0 focus:outline-none focus:outline-0 focus:ring-0 focus:ring-offset-0 hover:text-gray-800',
            disabled:
                'bg-transparent focus:ring-transparent text-gray-400 hover:text-gray-400 cursor-not-allowed border-0',
        },
        indigo: {
            default:
                'bg-indigo-500 hover:bg-indigo-700 focus:ring-indigo-300 text-white shadow-sm focus:shadow-sm',
            disabled:
                'bg-gray-200 hover:bg-gray-200 focus:ring-transparent text-gray-400 cursor-not-allowed shadow-sm focus:shadow-sm',
        },
        lime: {
            default:
                'bg-lime-500 hover:bg-lime-600 focus:ring-lime-400 text-white shadow-sm focus:shadow-sm',
            disabled:
                'bg-gray-200 hover:bg-gray-200 focus:ring-transparent text-gray-400 cursor-not-allowed shadow-sm focus:shadow-sm',
        },
        rose: {
            default:
                'bg-rose-500 hover:bg-rose-600 focus:ring-rose-400 text-white shadow-sm focus:shadow-sm',
            disabled:
                'bg-gray-200 hover:bg-gray-200 focus:ring-transparent text-gray-400 cursor-not-allowed shadow-sm focus:shadow-sm',
        },
        yellow: {
            default:
                'bg-yellow-400 hover:bg-yellow-500 focus:ring-yellow-300 text-white shadow-sm focus:shadow-sm',
            disabled:
                'bg-gray-200 hover:bg-gray-200 focus:ring-transparent text-gray-400 cursor-not-allowed shadow-sm focus:shadow-sm',
        },
        teal: {
            default:
                'bg-teal-400 hover:bg-teal-500 focus:ring-teal-300 text-white shadow-sm focus:shadow-sm',
            disabled:
                'bg-gray-200 hover:bg-gray-200 focus:ring-transparent text-gray-400 cursor-not-allowed shadow-sm focus:shadow-sm',
        },
    },
};

config.factory = {
    btn: (
        cfg = {
            disabled: false,
        },
    ) => {
        const _state = cfg.state || 'neutral';
        const _disabled = cfg.disabled || false;
        const src = [
            config.default,
            `-tpl-state-${_state} -tpl-theme-button -tpl-disabled-${
                _disabled === true ? 'true' : 'false'
            } -tpl-size-${
                cfg.size && Object.keys(config.size).includes(cfg.size) ? cfg.size : 'default-md'
            }`,
            cfg.size && Object.keys(config.size).includes(cfg.size)
                ? config.size[cfg.size]
                : config.size['sm'],
            !_disabled ? config.state[_state].default : config.state[_state].disabled,
        ];

        return src.join(' ');
    },
    btnIcon: (cfg = { size: 'sm' }) => {
        return cfg.size && Object.keys(config.icon).includes(cfg.size)
            ? config.icon[cfg.size]
            : config.icon['sm'];
    },
};

export const button = config;
export default button;
