<template>
    <Headify :data="headMeta" />

    <div class="bg-white min-h-screen">
        <NavMainHorizontal/>

        <div class="flex flex-1 flex-col pt-16">
            <main class="w-full mx-auto max-w-7xl px-2 sm:px-4 pt-12 md:pt-4">
                <div class="py-2">
                    <ListToolbar
                        :data="users"
                        :result="dataFiltered"
                        :search="toolbarConfig.search"
                        :buttons="toolbarConfig.buttons"
                        :index-id="toolbarConfig.indexId"
                    />
                </div>

                <div class="block">
                    <div
                        v-if="dataFiltered.length === 0"
                        :class="theme.f('alert', { state: 'warning' })"
                    >
                        <div class="font-medium py-1">
                            <MdiInformation :class="theme.f('alertIcon')" />
                            {{ t('Users') }}
                        </div>
                        <p class="py-1 text-sm">{{ t('notFoundText') }}</p>
                    </div>
                    <ul v-if="dataFiltered.length > 0" role="list" class="divide-y divide-gray-200">
                        <template v-for="user in users" :key="user.userName">
                            <li>
                                <router-link
                                    :to="{
                                        name: 'profile',
                                        params: {
                                            userid: user.id,
                                        },
                                    }"
                                    class="block hover:bg-gray-50"
                                >
                                    <div class="flex items-center py-4">
                                        <div class="min-w-0 flex-1 flex items-center">
                                            <div
                                                v-tippy="user.id"
                                                class="flex-shrink-0 font-medium text-sm px-2 text-gray-600"
                                            >
                                                <MdiAccountCog
                                                    class="flex-shrink-0 h-7 w-7 text-blue-600"
                                                />
                                            </div>
                                            <div
                                                class="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4"
                                            >
                                                <div>
                                                    <p
                                                        class="text-sm font-medium text-blue-600 truncate"
                                                    >
                                                        {{ user.userName }} ({{ user.firstName }}
                                                        {{ user.lastName }})
                                                    </p>
                                                    <p
                                                        class="mt-2 flex items-center text-sm text-gray-500"
                                                    >
                                                        <MdiEmail
                                                            class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                        />
                                                        <span class="truncate">
                                                            {{ user.email }}
                                                        </span>
                                                        <span
                                                            v-if="user.id === loggedUser.id"
                                                            class="inline-block rounded-md bg-cyan-600 text-xs text-white py-px px-2 mx-2"
                                                            >{{ t('me') }}</span
                                                        >
                                                    </p>
                                                </div>
                                                <div class="hidden md:block">
                                                    <div>
                                                        <p class="text-xs text-gray-900">
                                                            {{ t('created') }}:
                                                            <strong class="mx-1">
                                                                {{ user.createdBy }}
                                                            </strong>
                                                            <time :datetime="user.created">
                                                                {{
                                                                    $dayjs(user.created).format(
                                                                        'D. M. YYYY HH:mm',
                                                                    )
                                                                }}
                                                            </time>
                                                        </p>
                                                        <p
                                                            v-if="user.updated"
                                                            class="text-xs text-gray-900"
                                                        >
                                                            {{ t('updated') }}:
                                                            <strong class="mx-1">
                                                                {{ user.updatedBy }}
                                                            </strong>
                                                            <time :datetime="user.updated">
                                                                {{
                                                                    $dayjs(user.updated).format(
                                                                        'D. M. YYYY HH:mm',
                                                                    )
                                                                }}
                                                            </time>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="block">
                                                    <p
                                                        class="mt-2 flex items-center text-sm text-gray-500 font-medium"
                                                    >
                                                        <MdiCheckCircle
                                                            v-if="user.role !== 'NONE'"
                                                            class="flex-shrink-0 mr-2 h-5 w-5 text-emerald-400"
                                                        />
                                                        <MdiCancel
                                                            v-if="user.role === 'NONE'"
                                                            class="flex-shrink-0 mr-2 h-5 w-5 text-red-400"
                                                        />
                                                        {{ user.role }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <MdiChevronRight class="w-5 h-5 mr-2 text-gray-400" />
                                        </div>
                                    </div>
                                </router-link>
                            </li>
                        </template>
                    </ul>
                </div>
            </main>
        </div>
    </div>
</template>
<script setup>
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import navs from '../composables/navs';
import { listUsers } from '../composables/useUsers';
//import { getLoggedUser } from '../composables/useLoginMock';
import { getLoggedUser } from '../composables/useLogin';
import { t } from '../composables/i18n';
import { cloneData } from '../composables/utils';
import Headify from '../components/HeadifyComponent.vue';
import ListToolbar from '../components/ListToolbarComponent.vue';

import {
    MdiAccountCog,
    MdiCancel,
    MdiEmail,
    MdiCheckCircle,
    MdiChevronRight,
    MdiPlus,
    MdiInformation,
} from 'materialdesignicons-vue3/icons/';
import NavMainHorizontal from '../components/NavMainHorizontalComponent.vue';

const route = useRoute();

const toolbarConfig = reactive({
    indexId: 'id',
    search: {
        show: true,
        subject: '',
        keys: ['userName', 'firstName', 'lastName'],
    },
    buttons: [
        {
            action: () => {
                router.push({
                    name: 'profile',
                    params: {
                        userid: '0',
                    },
                });
            },
            show: true,
            title: t('buttonNewUser'),
            icon: MdiPlus,
        },
    ],
});

const loggedUser = getLoggedUser();

const users = reactive(await listUsers());
const dataFiltered = reactive(cloneData(users));

const router = useRouter();
const headMeta = navs().metaFind(route.name);
</script>
