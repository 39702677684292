import axios from 'axios';
import { objectToQueryString, mergeObject } from './utils';

export const r = async (input) => {
    const requestHeaders = {
        'Content-Type': 'application/json',
        //Accept: 'text/html,application/json,application/xhtml+xml,application/xml; q=0.9,*/*;q=0.8',
        //Accept: 'application/json',
        Accept: '*/*',
    };

    let {
        endpoint: endpoint = '',
        method: method = 'get',
        data: data = {},
        headers: headers = {},
        sessionToken: sessionToken = '',
        responseType: responseType = 'json',
        timeout: timeout = 7000,
    } = input;

    axios.defaults.timeout = timeout;
    axios.defaults.signal = AbortSignal.timeout(timeout);

    if (typeof sessionToken === 'string' && sessionToken.length > 0) {
        requestHeaders.Authorization = `Bearer ${sessionToken}`;
    }

    headers = mergeObject(requestHeaders, headers);

    try {
        /* console.log(`useApi.js: method ${method}, url ${endpoint}`, {
            data: data,
            headers: headers,
        }); */

        let result = {};

        if (method === 'get') {
            const sendDataUrl = Object.keys(data).length > 0 ? `?${objectToQueryString(data)}` : '';
            result = await axios.get(`${endpoint}${sendDataUrl}`, {
                headers: headers,
                responseType: responseType,
            });
        } else if (method === 'delete') {
            result = await axios.delete(`${endpoint}`, {
                headers: {
                    Authorization: `Bearer ${sessionToken}`,
                },
                responseType: responseType,
            });
        } else {
            result = await axios[method](`${endpoint}`, data, {
                headers: headers,
                responseType: responseType,
            });
        }

        const responseStatus = result.status;
        const o = await result.data;

        const allowedResponseStatuses = {
            200: 'OK',
            201: 'Created',
            202: 'Accepted',
            204: 'No Content',
        };

        //console.log('useApi.js: r result', result);
        if (responseStatus in allowedResponseStatuses) {
            return o;
        }

        return false;
    } catch (e) {
        console.log(`useApi.js: request error, method ${method}, url ${endpoint}`, {
            data: data,
            headers: headers,
            error: e,
        });
        return false;
    }
};

export const useApi = () => {
    return {
        r: r,
    };
};

export default useApi;
