<template>
    <div
        :class="[
            showConfig.show === true ? 'md:fixed left-0' : 'md:fixed -left-full',
            'bg-slate-50 transition-all ease-in-out duration-200 top-0 bottom-0 z-4000 md:w-2/6 md:max-w-2/6 grid-cols-none md:h-screen ' +
                'md:max-h-screen flex flex-col xl:static xl:top-auto xl:bottom-auto xl:left-auto col-span-1 md:col-span-3 xl:pt-16  pt-16  border-r-4 border-slate-100 xl:border-r-2 ' +
                'lg:text-base text-sm xl:w-auto xl:max-w-auto md:overflow-y-auto',
        ]"
    >
        <div class="h-12 flex gap-0 justify-between items-center p-0 select-none">
            <div class="font-bold text-center pt-8 pb-8 grow">
                {{ t('DocumentFilter') }}
            </div>
            <button
                v-tippy="t('buttonClose')"
                type="button"
                :class="`
                            ${theme.f('btn', {
                                state: 'transparent',
                                disabled: false,
                                size: 'lg',
                            })} hidden md:block xl:hidden
                        `"
                @click="showConfig.show = false"
            >
                <MdiClose class="text-black-400" :class="theme.f('btnIcon', { size: 'lg' })" />
            </button>
        </div>

        <div class=" ">
            <div
                v-for="(item, index) in Object.keys(config.filterFields)"
                :key="`${item}`"
                class="flex px-2 md:px-4"
            >
                <div class="flex items-center pl-2">
                    <label class="w-16 lg:w-24 block py-1 text-xs lg:text-sm font-semibold">
                        {{ t(item) }}:
                    </label>
                </div>
                <div class="flex flex-wrap grow gap-2 py-3 pl-2">
                    <div
                        v-if="
                            documentTypedParams[item] === 'date' ||
                            documentTypedParams[item] === 'string'
                        "
                        class="flex items-center"
                    >
                        <select
                            id="condition-operator"
                            v-model="config.selectedFilterParams.attributes[item].condition"
                            name="condition-operator"
                            class="bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-xs lg:text-sm min-w-[120px] w-full"
                        >
                            <option
                                v-for="field in documentTypedParams[item] === 'date'
                                    ? dateOperators
                                    : stringOperators"
                                :key="field"
                                :value="field"
                            >
                                {{ t(field) }}
                            </option>
                        </select>
                    </div>

                    <div class="flex grow">
                        <div class="flex grow items-center">
                            <div
                                v-if="
                                    documentTypedParams[item] === 'boolean' ||
                                    documentTypedParams[item] === 'string'
                                "
                                class="flex items-center grow"
                            >
                                <div class="flex flex-1">
                                    <input
                                        :id="`${item}`"
                                        v-model="config.selectedFilterParams.attributes[item].value"
                                        :type="getInputType(documentTypedParams[item])"
                                        :name="`${item}`"
                                        autocomplete="off"
                                        class="border rounded-md shadow-sm focus:outline-none text-xs lg:text-sm border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        :class="
                                            documentTypedParams[item] !== 'boolean' ? 'w-full' : ''
                                        "
                                        @keydown="config.searchButtonDisabled = false"
                                        @change="config.searchButtonDisabled = false"
                                    />
                                </div>
                            </div>

                            <div
                                v-if="documentTypedParams[item] === 'date'"
                                class="flex items-center grow flex-1"
                            >
                                <VueDatePicker
                                    v-model="config.selectedFilterParams.attributes[item].value"
                                    format="yyyy-MM-dd HH:mm:SSS"
                                    enable-seconds
                                    @update:model-value="config.searchButtonDisabled = false"
                                />
                            </div>
                            <div
                                v-if="documentTypedParams[item] === 'docType'"
                                class="flex items-center grow"
                            >
                                <select
                                    id="document-type"
                                    v-model="config.selectedFilterParams.attributes[item].value"
                                    name="document-type"
                                    class="bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-xs lg:text-sm w-full"
                                    @change="selectDocType()"
                                >
                                    <option
                                        v-for="docType in Object.keys(documentTypes)"
                                        :key="docType"
                                        :value="docType"
                                    >
                                        {{ t(docType) }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex items-center">
                    <button
                        :class="
                            theme.f('btn', {
                                state: 'transparent',
                                disabled: Object.keys(config.filterFields).length <= 1,
                                font: 'font-bold',
                            })
                        "
                        :disabled="Object.keys(config.filterFields).length <= 1"
                        @click="removeFilterParameter(item)"
                    >
                        <MdiClose
                            class="text-black-400"
                            :class="theme.f('btnIcon', { size: 'sm' })"
                        />
                    </button>
                </div>
            </div>
            <div
                v-if="!config.showNewParameterSelector && getAvailableAttributes().length > 0"
                class="flex justify-center items-center"
            >
                <button
                    :class="
                        theme.f('btn', {
                            state: 'transparent',
                            disabled: false,
                        })
                    "
                    @click="config.showNewParameterSelector = true"
                >
                    <MdiPlus :class="theme.f('btnIcon')" />
                    <div class="text-sm font-semibold">{{ t('buttonNewAttribute') }}</div>
                </button>
            </div>
            <div v-if="config.showNewParameterSelector" class="flex grid py-4 px-8">
                <select
                    id="parameter-name"
                    v-model="config.selectedParam"
                    name="parameter-name"
                    class="mt-1 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-xs lg:text-sm"
                    @change="addFilterParameter(config.selectedParam)"
                >
                    <option v-for="field in getAvailableAttributes()" :key="field" :value="field">
                        {{ t(field) }}
                    </option>
                </select>
            </div>
            <div
                v-if="
                    config.customFilterFields && Object.keys(config.customFilterFields).length > 0
                "
                class="mx-8 my-2 border-t-2 border-slate-300"
            ></div>
            <div
                v-if="
                    config.customFilterFields && Object.keys(config.customFilterFields).length > 0
                "
            >
                <div
                    v-for="(item, index) in Object.keys(config.customFilterFields)"
                    :key="`${item}`"
                    class="flex px-2 md:px-4"
                >
                    <div class="flex items-center pl-2">
                        <label class="w-16 lg:w-24 block py-1 text-xs lg:text-sm font-semibold">
                            {{ t(item) }}:
                        </label>
                    </div>

                    <div class="flex flex-wrap grow gap-2 py-3 pl-2">
                        <div
                            v-if="
                                config.customFilterFields[item] === 'DATETIME' ||
                                config.customFilterFields[item] === 'TEXT' ||
                                config.customFilterFields[item] === 'INT'
                            "
                            class="flex items-center"
                        >
                            <select
                                id="cust-condition-operator"
                                v-model="config.selectedFilterParams.attributes[item].condition"
                                name="cust-condition-operator"
                                class="bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-xs lg:text-sm w-28"
                            >
                                <option
                                    v-for="field in getConditionValues(
                                        config.customFilterFields[item],
                                    )"
                                    :key="field"
                                    :value="field"
                                >
                                    {{ t(field) }}
                                </option>
                            </select>
                        </div>

                        <div class="flex grow">
                            <div class="flex grow items-center">
                                <div
                                    v-if="
                                        config.customFilterFields[item] === 'TEXT' ||
                                        config.customFilterFields[item] === 'INT'
                                    "
                                    class="flex items-center flex-1"
                                >
                                    <input
                                        :id="`${item}`"
                                        v-model="config.selectedFilterParams.attributes[item].value"
                                        :type="getInputType(config.customFilterFields[item])"
                                        :name="`${item}`"
                                        autocomplete="off"
                                        class="border rounded-md shadow-sm focus:outline-none text-xs lg:text-sm border-gray-300 focus:ring-blue-500 focus:border-blue-500 min-w-[145px]"
                                        :class="
                                            config.customFilterFields[item] !== 'boolean'
                                                ? 'w-full'
                                                : ''
                                        "
                                        @keydown="config.searchButtonDisabled = false"
                                        @change="config.searchButtonDisabled = false"
                                    />
                                </div>
                                <div
                                    v-if="config.customFilterFields[item] === 'DATETIME'"
                                    class="flex items-center flex-1"
                                >
                                    <VueDatePicker
                                        v-model="config.selectedFilterParams.attributes[item].value"
                                        format="yyyy-MM-dd HH:mm:SSS"
                                        enable-seconds
                                        @update:model-value="config.searchButtonDisabled = false"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center">
                        <div class="flex-none">
                            <button
                                :class="
                                    theme.f('btn', {
                                        state: 'transparent',
                                        disabled: Object.keys(config.filterFields).length <= 1,
                                        font: 'font-bold',
                                    })
                                "
                                :disabled="Object.keys(config.filterFields).length <= 1"
                                @click="removeFilterParameter(item, true)"
                            >
                                <MdiClose
                                    class="text-black-400"
                                    :class="theme.f('btnIcon', { size: 'sm' })"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="
                    !config.showNewCustomParameterSelector &&
                    getAvailableCustomAttributes().length > 0
                "
                class="flex pb-4 justify-center items-center"
            >
                <button
                    :class="
                        theme.f('btn', {
                            state: 'transparent',
                            disabled: false,
                            font: 'font-bold',
                        })
                    "
                    @click="config.showNewCustomParameterSelector = true"
                >
                    <MdiPlus :class="theme.f('btnIcon')" />
                    <div class="text-xs lg:text-sm font-semibold">
                        {{ t('buttonNewCustomAttribute') }}
                    </div>
                </button>
            </div>
            <div v-if="config.showNewCustomParameterSelector" class="flex grid py-4 px-8">
                <select
                    id="parameter-name"
                    v-model="config.selectedCustomParam"
                    name="parameter-name"
                    class="mt-1 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-xs lg:text-sm"
                    @change="addCustomFilterParameter(config.selectedCustomParam)"
                >
                    <option v-for="field in getAvailableCustomAttributes()" :key="field">
                        {{ field }}
                    </option>
                </select>
            </div>

            <div class="flex px-8">
                <div class="flex-1">
                    <button
                        :class="
                            theme.f('btn', {
                                state: 'danger',
                                disabled:
                                    config.resetFilterButtonDisabled ||
                                    documentDetailsConfig.showDocVersions,
                            })
                        "
                        :disabled="
                            config.resetFilterButtonDisabled ||
                            documentDetailsConfig.showDocVersions
                        "
                        @click="resetFilter(documentFilterParams)"
                    >
                        <MdiFilterOff :class="theme.f('btnIcon')" />
                        {{ t('buttonResetFilter') }}
                    </button>
                </div>
                <div>
                    <button
                        :class="
                            theme.f('btn', {
                                state: 'primary',
                                disabled:
                                    config.searchButtonDisabled ||
                                    documentDetailsConfig.showDocVersions,
                            })
                        "
                        :disabled="
                            config.searchButtonDisabled || documentDetailsConfig.showDocVersions
                        "
                        @click="filterDocs(config.selectedFilterParams)"
                    >
                        <MdiMagnify :class="theme.f('btnIcon')" />
                        {{ t('search') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive } from 'vue';
import { t } from '../composables/i18n';
import { MdiClose, MdiMagnify, MdiFilterOff, MdiPlus } from 'materialdesignicons-vue3/icons/';
import { getConditions, getStringConditions } from '../composables/useFilter';
import { getInputType } from '../composables/docUtils';
import { cloneData } from '../composables/utils';
import VueDatePicker from '@vuepic/vue-datepicker';

const props = defineProps({
    documentTypedParams: {
        type: Object,
        required: true,
    },
    documentFilterParams: {
        type: Object,
        required: true,
    },
    resetFilteredDocuments: {
        type: Function,
        required: true,
    },
    filterDocumentsData: {
        type: Function,
        required: true,
    },
    documentTypes: {
        type: Object,
        required: true,
    },
    documentDetailsConfig: {
        type: Object,
        required: true,
    },
    showConfig: {
        type: Object,
        required: true,
    },
});

const documentFilterParams = props.documentFilterParams;
const documentDetailsConfig = props.documentDetailsConfig;
const showConfig = props.showConfig;

const config = reactive({
    searchButtonDisabled: true,
    resetFilterButtonDisabled: true,
    show: true,
    filterFields: { docType: null, name: null, creationDate: null },
    customFilterFields: {},
    showNewParameterSelector: false,
    showNewCustomParameterSelector: false,
    selectedParam: null,
    selectedCustomParam: null,
    selectedFilterParams: cloneData(documentFilterParams),
    testdate: null,

});

const documentTypedParams = props.documentTypedParams;
const documentTypes = props.documentTypes;

const dateOperators = getConditions();
const stringOperators = getStringConditions();
const numberOperators = ['equals'];

const filterDocs = async (selectedFilterParams) => {
    documentFilterParams.attributes = cloneData(selectedFilterParams.attributes);
    props.filterDocumentsData(documentFilterParams);
    if (Object.values(selectedFilterParams.attributes).some((param) => param.value !== null)) {
        config.resetFilterButtonDisabled = false;
    }
};

const resetFilter = async (documentFilterParams) => {
    console.log('resetFilter', documentFilterParams);
    config.customFilterFields = {};
    resetAllAttributes(documentFilterParams);
    resetAllAttributes(config.selectedFilterParams);
    props.resetFilteredDocuments();
    config.resetFilterButtonDisabled = true;
};

const addFilterParameter = (parameterName) => {
    config.filterFields[parameterName] = null;
    config.showNewParameterSelector = false;
};

const addCustomFilterParameter = (parameterName) => {
    config.customFilterFields[parameterName] = null;
    config.showNewCustomParameterSelector = false;
};

const removeFilterParameter = (parameterName, isCustom = false) => {
    if (isCustom) {
        delete config.customFilterFields[parameterName];
    } else {
        delete config.filterFields[parameterName];
        if (
            !Object.values(config.selectedFilterParams.attributes.attributes).some(
                (param) => param && param.value !== null,
            )
        ) {
            config.resetFilterButtonDisabled = true;
        }

        if (parameterName === 'docType') {
            config.customFilterFields = {};
        }
    }
    config.selectedFilterParams.attributes[parameterName] = { value: null, condition: null };

};

const getAvailableAttributes = () => {
    return Object.keys(documentTypedParams).filter((param) => {
        return !Object.keys(config.filterFields).includes(param);
    });
};

const getAvailableCustomAttributes = () => {
    if (config.selectedFilterParams.attributes.docType.value) {
        return Object.keys(
            documentTypes[config.selectedFilterParams.attributes.docType.value],
        ).filter((param) => {
            return !Object.keys(config.customFilterFields).includes(param);
        });
    }
    return [];
};

const selectDocType = () => {
    config.searchButtonDisabled = false;

    if (
        config.selectedFilterParams.attributes.docType.value &&
        documentTypes[config.selectedFilterParams.attributes.docType.value]
    ) {
        const customFields = {
            ...documentTypes[config.selectedFilterParams.attributes.docType.value],
        };
        Object.keys(customFields).forEach((paramName) => {
            config.selectedFilterParams.attributes[paramName] = {};
        });

        config.customFilterFields = customFields;
    }
};

const getConditionValues = (paramType) => {
    if (paramType === 'DATETIME') {
        return dateOperators;
    } else if (paramType === 'INT') {
        return numberOperators;
    } else {
        return stringOperators;
    }
};

const resetAllAttributes = async (object) => {
    Object.keys(object.attributes).forEach((key) => {
        if (object.attributes[key].value) {
            object.attributes[key].value = null;
        }

        if (object.attributes[key].condition) {
            object.attributes[key].condition = null;
        }
    });
};
</script>
