<template>
    <Headify :data="headMeta" />

    <div class="bg-white min-h-screen">
        <NavMainHorizontal />

        <div class="flex flex-1 flex-col pt-16">
            <!-- <main class="flex-1"> -->
            <main class="w-full mx-auto max-w-7xl px-2 sm:px-4 pt-12 md:pt-4">
                <div class="py-6 space-y-6">
                    <form
                        class="sm:grid sm:grid-cols-3"
                        action="/"
                        method="post"
                        @submit.stop.prevent="preventSubmit($event)"
                    >
                        <div class="sm:col-span-2">
                            <div class="www py-2 px-1 sm:p-4">
                                <label
                                    for="first-name"
                                    class="block text-sm font-medium text-gray-700 select-none"
                                >
                                    {{ t('userName') }}
                                </label>
                                <input
                                    id="user-name"
                                    v-model="user.userName"
                                    type="text"
                                    name="first-name"
                                    autocomplete="off"
                                    class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm"
                                    :class="
                                        user.userName.length === 0
                                            ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                                            : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
                                    "
                                />
                            </div>

                            <div class="www py-2 px-1 sm:p-4">
                                <label
                                    for="first-name"
                                    class="block text-sm font-medium text-gray-700 select-none"
                                >
                                    {{ t('firstName') }}
                                </label>
                                <input
                                    id="first-name"
                                    v-model="user.firstName"
                                    type="text"
                                    name="first-name"
                                    autocomplete="off"
                                    class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm"
                                    :class="
                                        user.firstName.length === 0
                                            ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                                            : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
                                    "
                                />
                            </div>

                            <div class="www py-2 px-1 sm:p-4">
                                <label
                                    for="last-name"
                                    class="block text-sm font-medium text-gray-700 select-none"
                                >
                                    {{ t('lastName') }}
                                </label>
                                <input
                                    id="last-name"
                                    v-model="user.lastName"
                                    type="text"
                                    name="last-name"
                                    autocomplete="off"
                                    class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm"
                                    :class="
                                        user.lastName.length === 0
                                            ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                                            : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
                                    "
                                />
                            </div>

                            <div class="www py-2 px-1 sm:p-4">
                                <label
                                    for="email-address"
                                    class="block text-sm font-medium text-gray-700 select-none"
                                >
                                    {{ t('email') }}
                                </label>
                                <input
                                    id="email-address"
                                    v-model="user.email"
                                    type="text"
                                    name="email-address"
                                    autocomplete="off"
                                    class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm"
                                    :class="
                                        emailIsValid === false
                                            ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                                            : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
                                    "
                                />
                            </div>

                            <div class="www py-2 px-1 sm:p-4 grid grid-cols-2 gap-6">
                                <div>
                                    <label
                                        for="password"
                                        class="block text-sm font-medium text-gray-700 select-none"
                                    >
                                        {{ t('password') }}
                                    </label>
                                    <input
                                        id="password"
                                        v-model="user.password"
                                        type="password"
                                        name="password"
                                        autocomplete="off"
                                        class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    />
                                </div>
                                <div>
                                    <label
                                        for="passwordAgain"
                                        class="block text-sm font-medium text-gray-700 select-none"
                                    >
                                        {{ t('passwordAgain') }}
                                    </label>
                                    <input
                                        id="passwordAgain"
                                        v-model="user.passwordAgain"
                                        type="password"
                                        name="passwordAgain"
                                        autocomplete="off"
                                        class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    />
                                </div>
                                <div
                                    v-if="user.password.length > 0 && notPassedStrings.length > 0"
                                    class="p-2 rounded-md bg-red-300 text-red-800 col-span-2 text-sm font-medium select-none"
                                >
                                    <p v-for="err in notPassedStrings" :key="err" class="py-1">
                                        <MdiAlert class="w-4 h-4 -mt-1 mr-0.5 inline" />
                                        {{ err }}
                                    </p>
                                </div>
                                <div
                                    v-if="
                                        user.password.length > 0 &&
                                        user.passwordAgain.length > 0 &&
                                        user.password !== user.passwordAgain
                                    "
                                    class="p-2 rounded-md bg-red-300 text-red-800 col-span-2 text-sm font-medium select-none"
                                >
                                    <p class="py-1">
                                        <MdiAlert class="w-4 h-4 -mt-1 mr-0.5 inline" />
                                        {{ t('passwordNotMatch') }}
                                    </p>
                                </div>
                                <div
                                    v-if="
                                        user.password.length === 0 ||
                                        user.passwordAgain.length === 0
                                    "
                                    class="p-2 rounded-md bg-blue-300 text-blue-800 col-span-2 text-sm font-medium select-none"
                                >
                                    <MdiInformation class="w-4 h-4 -mt-1 mr-0.5 inline" />
                                    {{ t('passwordInfo') }}
                                </div>
                            </div>

                            <div class="www py-2 px-1 sm:p-4 text-right select-none">
                                <button
                                    :class="`${theme.f('btn', {
                                        state: 'neutral',
                                    })} mr-2`"
                                    @click="
                                        loggedUser.role === 'ADMIN'
                                            ? router.push({ name: 'users' })
                                            : router.go(-1)
                                    "
                                >
                                    <MdiChevronLeft :class="theme.f('btnIcon')" />
                                    {{ t('buttonBack') }}
                                </button>

                                <button
                                    :class="
                                        theme.f('btn', {
                                            state: 'primary',
                                            disabled: saveForm === false,
                                        })
                                    "
                                    :disabled="saveForm === false"
                                    @click="
                                        saving.active = true;
                                        saveUser(userid, user, saving);
                                    "
                                >
                                    <MdiContentSave :class="theme.f('btnIcon')" />
                                    {{ t('buttonSave') }}
                                </button>
                            </div>
                        </div>

                        <div class="block rounded-md py-4 sm:py-0 select-none">
                            <div v-if="setProfileRole">
                                <div class="www py-2 px-1 sm:p-4">
                                    <label
                                        for="role"
                                        class="block text-sm font-medium text-gray-700"
                                    >
                                        {{ t('role') }}
                                    </label>
                                    <select
                                        id="role"
                                        v-model="user.role"
                                        name="role"
                                        class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                        :disabled="loggedUser.role !== 'ADMIN'"
                                    >
                                        <option v-for="r in config.roles.all" :key="r">
                                            {{ r.length === 0 ? 'NONE' : r }}
                                        </option>
                                    </select>
                                </div>

                                <div class="www py-2 px-1 sm:p-4 text-right">
                                    <button
                                        :class="
                                            theme.f('btn', {
                                                state: 'danger',
                                                disabled: saving.active === true,
                                            })
                                        "
                                        :disabled="saving.active === true"
                                        @click="
                                            saving.active = true;
                                            saveUserRole();
                                        "
                                    >
                                        <MdiAccountLock :class="theme.f('btnIcon')" />
                                        {{ t('buttonSetRole') }}
                                    </button>
                                </div>
                            </div>

                            <div v-if="setProfileRole === false">
                                <div class="www py-2 px-1 sm:p-4">
                                    <label
                                        for="role"
                                        class="block text-sm font-medium text-gray-700"
                                    >
                                        {{ t('role') }}
                                    </label>
                                    <p
                                        class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    >
                                        {{ user.role }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </main>
        </div>
    </div>

    <Spinner
        v-if="saving.active"
        type="dots"
        :color="`text-blue-600`"
        :color2="`text-emerald-600`"
        :text-color="`text-emerald-400`"
        :overlay="true"
        :speed="1000"
        :dots="true"
        :dot-char="'.'"
        :dot-dual="false"
        :dot-reverse="false"
        :text="saving.text"
        :inline="false"
        :pulse="true"
    />
</template>

<script setup>
import { reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import navs from '../composables/navs';
//import { getLoggedUser } from '../composables/useLoginMock';
import { getLoggedUser } from '../composables/useLogin';
import { getUser, saveUser, patchUser } from '../composables/useUsers';
import { notify } from 'notiwind';
import { load } from '../composables/useConfig';
import { validateEmail } from '../composables/utils';
import { t } from '../composables/i18n';
import Breadcrumbs from '../components/BreadcrumbsComponent.vue';
import Headify from '../components/HeadifyComponent.vue';
import Spinner from '../components/SpinnerComponent.vue';
import NavMainHorizontal from '../components/NavMainHorizontalComponent.vue';

import {
    MdiAccountLock,
    MdiContentSave,
    MdiChevronLeft,
    MdiInformation,
    MdiAlert,
} from 'materialdesignicons-vue3/icons/';

const loggedUser = getLoggedUser();

const route = useRoute(),
    userid = route.params.userid || '0',
    router = useRouter(),
    user = reactive(await getUser(userid)),
    saving = reactive({
        active: false,
        text: `${t('loading')}`,
    }),
    config = load(),
    saveForm = ref(false),
    emailIsValid = ref(false),
    passwordIsValid = ref(false),
    setProfileRole =
        user.id !== '0' && user.id !== loggedUser.id && loggedUser.role === 'ADMIN' ? true : false;

if (user.id === '0' && loggedUser.role !== 'ADMIN') {
    router.push('/');
}

let notPassedStrings = reactive([]);

user.password = '';
user.passwordAgain = '';

const saveUserRole = async () => {
    saving.active = true;
    const res = await patchUser(
        user.id,
        {
            role: user.role,
        },
        saving,
    );

    if (res !== false) {
        saving.active = false;
        notify(
            {
                group: 'success',
                title: 'Done',
                text: `Role ${user.role} saved for user ${user.userName}`,
            },
            2500,
        );
    } else {
        // Notify problem
        notify(
            {
                group: 'error',
                title: 'Error',
                text: `Error saving role ${user.role} for ${user.userName}`,
            },
            5000,
        );
        saving.active = false;
    }
};

const passwordComplexity = (password) => {
    const passRules = [
            {
                score: 2,
                regex: '[a-z]',
            },
            {
                score: 3,
                regex: '[A-Z]',
            },
            {
                score: 4,
                regex: '[0-9]',
            },
            {
                score: 5,
                regex: '[!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~]',
            },
            {
                score: 1,
                regex: '^.{8,32}$',
            },
        ],
        requiredScore = [1, 2, 3, 4, 5];

    notPassedStrings = [];

    const score = passRules
        .filter((rule) => {
            const result = new RegExp(rule.regex).test(password);
            if (!result) {
                notPassedStrings.push(t(`passwordRulesUnmatched${rule.score}`));
            }
            return result;
        })
        .map((rule) => {
            return rule.score;
        });

    // Normalize comparable objects
    score.sort();
    requiredScore.sort();
    const allow = JSON.stringify(score) === JSON.stringify(requiredScore);
    /*
    console.warn('------------ PW SCORE START ------------');
    console.table(notPassedStrings);
    console.log('Password', password);
    console.log('Passed', allow);
    console.log('Score', score);
    */
    return allow;
};

watch(user, (u) => {
    validateUserData(u);
    //console.log('User watch', u.password);
    //console.table([saveForm.value, emailIsValid.value, passwordIsValid.value]);
});

const preventSubmit = () => {
    return false;
};

const validateUserData = (u) => {
    if (u.password.length > 0) {
        passwordIsValid.value = passwordComplexity(u.password);
    } else {
        passwordIsValid.value = true;
    }
    emailIsValid.value = validateEmail(u.email);

    saveForm.value =
        emailIsValid.value === true &&
        passwordIsValid.value === true &&
        u.firstName.length > 0 &&
        u.lastName.length > 0 &&
        u.userName.length > 0 &&
        u.password === u.passwordAgain;
};

validateUserData(user);

const breadcrumbsLinks = [
    {
        name: 'dashboard',
        title: t('Dashboard'),
    },
];

if (loggedUser.role === 'ADMIN' && user.id !== loggedUser.id) {
    breadcrumbsLinks.push({
        name: 'users',
        title: t('Users'),
    });
}

breadcrumbsLinks.push({
    title:
        user.id === loggedUser.id
            ? `${t('MyProfile')}: ${user.userName}`
            : `${t('Profile')}: ${user.userName}`,
    final: true,
});

const headMeta = navs().metaFind(route.name);
</script>
