import { r } from './useApi';
import { keeper } from './utils';

export const getBackendServerInfo = async () => {
    const res = await r({
        endpoint: `${keeper('apiUrl')}/actuator/info`,
    });
    return res;
};



export const pingBackendServer = async () => {
    const res = await r({
        endpoint: `${keeper('apiUrl')}/actuator/health`,
        timeout: 1500,
    });
    return res;
};



export const useStats = () => {
    return {
        getBackendServerInfo: getBackendServerInfo,
        pingBackendServer: pingBackendServer
    };
};

export default useStats;
