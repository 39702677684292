const cs = () => {
    const all = {
        appName: 'SoftDM',
        me: 'Já',
        items: '[0] žádná položka |[1] 1 položka |[2,4] {count} položky |[5,*] {count} položek ',
        existence: '[0] Neexistuje |[1] Jedna |[2,4] Méně než pět |[5,*] Pět a vice ',
        test: '[0] žádná položka |[1] jedna položka |[2,4] pár položek ({count}) |[5,*] {count} položek',
        loginPlaceholder: 'Uživatelské jméno nebo email',
        cantLogin: 'Nemohu se přihlásit',
        pwdPlaceholder: 'Heslo',
        loading: 'Nahrávání',
        loadingExt: 'Nahrávání {ext}',
        saving: 'Ukládání',
        savingExt: 'Ukládání {ext}',
        sending: 'Odesílání',
        sendingExt: 'Odesílání {ext}',
        validating: 'Probíhá validace',
        validatingExt: 'Probíhá validace {ext}',
        search: 'Vyhledat',
        Dashboard: 'Úvod',

        Documents: 'Dokumenty',
        Document: 'Dokument',
        DocumentName: 'Název dokumentu',
        DocumentParameters: 'Parametry dokumentu',
        DocumentFilter: 'Filtr',
        buttonOpenFilter: 'Otevřít filtr',
        buttonResetFilter: 'Zrušit filtr',
        buttonNewAttribute: 'Přidat atribut',
        buttonNewCustomAttribute: 'Přidat custom atribut',
        buttonNew: 'Nový',
        buttonSelectDocs: 'Vybrat dokumenty',
        buttonSelectDocsEnd: 'Ukončit výběr',
        buttonNewDocument: 'Nový dokument', 
        newVersion: 'Nová verze dokumentu',
        buttonEditTable: 'Upravit zobrazení',
        buttonSaveTable: 'Uložit zobrazení',
        buttonDownload: 'Stáhnout',
        buttonNewVersion: 'Nová verze',
        showAllParams: 'Ukázat všechny parametry',
        showCustomParams: 'Ukázat custom parametry',
        newColumn: 'Přidat sloupec',
        newCustomColumn: 'Přidat custom sloupec',
        page: 'Stránka',
        dragAndDropTitle: 'Přetáhnete dokument tady nebo kliknete pro nahraní',
        dragBeforeDropTitle: 'Pusťte dokument pro nahraní',
        removeDoc: 'Odstranit dokument',
        documentPreview: 'Náhled',
        documentPreviewHide: 'Skrýt',
        documentDeactivate: 'Deaktivovat',
        documentEdit: 'Editovat',
        documentShowVersions: 'Ukázat verze dokumentu',
        documentBackToFilter: 'Zpět na výsledek hledání',
        documentName: 'Název',
        documentTitle: 'Titulek',
        documentDescription: 'Popis',
        documentComment: 'Popis',
        before: 'před',
        after: 'po',
        equals: 'rovná se',
        contains: 'obsahuje',
        starts: 'začíná',
        ends: 'končí',
        docType: 'Typ dokumentu',
        documentId: 'ID dokumentu',
        versionsId: 'ID verze',
        parentId: 'ID rodiče',
        version: 'Verze',
        comment: 'Komentář',
        current: 'Poslední verze',
        contentSize: 'Velikost',
        name: 'Název',
        title: 'Titul',
        description: 'Popis',
        extension: 'Přípona',
        mimeType: 'Média typ',
        owner: 'Majitel',
        creator: 'Vytvořil',
        modifier: 'Upravil',
        creationDate: 'Datum vytvoření',
        modifyDate: 'Datum úpravy',
        locked: 'Zamčený',
        deleted: 'Smazaný',
        lockedBy: 'Zamčeno uživatelem',
        noPreviewAvailabe: 'Obsah dokumentu neexistuje',
        DOCUMENT: 'Dokument',
        INVOICE: 'Faktura',
        CONTRACT: 'Smlouva',
        ORDER: 'Objednávka',
        STATEMENT: 'Výpis',
    
        document: 'Dokument',
        invDate: 'Datum vystavení faktury',
        dueDate: 'Datum splatnosti',
        invNumber: 'Číslo faktury',
        amount: 'Částka',
        invDesc: 'Popis faktury',
        account: 'Účet',
        currency: 'Měna',
        supplName: 'Název dodavatele',
        supplStreet: 'Ulice dodavatele',
        supplCity: 'Město dodavatele',
        supplState: 'Stát dodavatele',
        supplZipCode: 'PSČ dodavatele',
        supplCountry: 'Země dodavatele',
        supplEmail: 'Email dodavatele',
        contractNumber: 'Číslo smlouvy',
        contractDate: 'Datum smlouvy',
        NOTE: 'Poznámka',
        requestNumber: 'Číslo žádosti',
        requestDate: 'Datum žádosti',
        paymentType: 'Typ platby',
        note: 'Poznámka',
        deliveryMethod: 'Způsob doručení',
        supplier: 'Dodavatel',
        statementNumber: 'Číslo výpisu',
        statementDate: 'Datum výpisu',
        accountDetails: 'Detaily účtu',
        docDeactivationConfirmationTitle: 'Potvrzení  deaktivaci',
        docDeactivationConfirmationText: 'Deaktivovat dokument s ID',
        docDeactivationConfirmationMultipleText: 'Deaktivovat dokumenty s ID',
        previewClose: 'Zavřít preview',
        documentsStatsNote: 'Druhy dokumentu',
    
        Profile: 'Profil',
        MyProfile: 'Můj profil',
        Sorry: 'Bohužel',
        Error: 'Chyba',
        NoPermission: 'Nemáte oprávnění pro {path}',
        MissingRequired: 'Chybí povinné údaje: {subject}',
        Users: 'Uživatelé',
        role: 'Role',
        userName: 'Uživatelské jméno',
        firstName: 'Jméno',
        lastName: 'Příjmení',
        email: 'Email',
        notFound: 'Nenalezeno',
        notFoundText: 'Nic nebylo nalezeno',
        password: 'Heslo',
        passwordAgain: 'Heslo znovu',
        passwordInfo: 'Heslo vyplňte pouze v případě, že ho chcete změnit.',
        passwordNotMatch: 'Hesla se neshodují!',
        passwordRulesUnmatched1: 'Délka hesla musí být 8 - 32 znaků',
        passwordRulesUnmatched2: 'Heslo musí obsahovat alespoň jedno malé písmeno',
        passwordRulesUnmatched3: 'Heslo musí obsahovat alespoň jedno velké písmeno',
        passwordRulesUnmatched4: 'Heslo musí obsahovat alespoň jedno číslo',
        passwordRulesUnmatched5: 'Heslo musí obsahovat alespoň jeden nealfanumerický znak',
        Settings: 'Nastavení klienta',
        
        latest: 'Nejnovější',
  
        versionCount: 'Počet verzí',
        created: 'Vytvořeno',
        updated: 'Poslední změna',
        Login: 'Přihlášení',
        Logout: 'Odhlásit',
        UITest: 'UI test',
        language: 'Jazyk',
        cs: 'Čeština',
        en: 'English',
        copyToClipboard: 'Zkopírovat do schránky',
        copiedToClipboard: 'Zkopírováno do schránky',
        
        buttonOk: 'Ok',
        buttonChoose: 'Vybrat',
        buttonCancel: 'Zrušit',
        buttonClose: 'Zavřít',
        buttonInsert: 'Vložit',
        buttonPreview: 'Vytvořit náhled',
        buttonYes: 'Ano',
        buttonNo: 'Ne',
        buttonBack: 'Zpět',
        buttonSave: 'Uložit',
        buttonSaveAs: 'Uložit jako...',
        buttonActivate: 'Aktivovat',
        buttonDectivate: 'Deaktivovat',
        buttonSend: 'Odeslat',
        buttonAdd: 'Přidat',
        buttonAddRow: 'Přidat řádek',
        buttonRemove: 'Odebrat',
        buttonReset: 'Vymazat',
        buttonNewUser: 'Nový uživatel',
        buttonSetRole: 'Nastavit roli',
        buttonLogin: 'Přihlásit',
        welcomeText: 'Vítejte v aplikaci',
        backendServerNote: 'Backendový server',
        serverOk: '{serverName} je dostupný',
        serverFail: '{serverName} je nedostupný',
    };

    return {
        all,
    };
};

export default cs;
