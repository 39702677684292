const config = {
    default: 'transition-colors select-none',
    icon: {
        brand: {
            desktop: 'h-5 w-5 shrink-0',
            mobile: 'h-5 w-5 shrink-0',
        },
    },
    state: {
        brand: {
            desktop: 'bg-slate-700 text-white',
            mobile: 'bg-slate-900 text-white',
        },
        info: {
            desktop: 'bg-slate-900 text-white',
            mobile: 'bg-slate-900 text-white',
        },
    },
    link: {
        brand: {
            default: {
                desktop: 'text-slate-300 hover:bg-slate-800 hover:text-slate-200',
                mobile: 'bg-slate-900 text-slate-100',
            },
            active: {
                desktop: 'bg-slate-800 text-slate-200',
                mobile: 'text-slate-200 hover:bg-slate-600',
            },
        },
        highlight: {
            default: {
                desktop: '',
                mobile: 'text-amber-400 group-hover:text-amber-200 group-hover:bg-slate-900',
            },
            active: {
                desktop: 'bg-slate-900 text-slate-100',
                mobile: 'text-slate-200 hover:bg-slate-600',
            },
        },
        info: {
            desktop: '',
            mobile: '',
        },
    },
};

config.factory = {
    nav: (cfg = {}) => {
        const src = [nav.default, nav.state[cfg.state || 'brand'][cfg.platform || 'desktop']];
        return src.join(' ');
    },
    navIcon: () => {
        return nav.icon;
    },
};

export const nav = config;
export default nav;
