import { r } from './useApi';
import { keeper } from './utils';
import { Buffer } from 'buffer';
import { fileExtension } from '../composables/utils';

import {
    MdiReceiptTextOutline ,
    MdiTextBoxOutline,
    MdiFileDocumentOutline,
    MdiFileDocumentEditOutline,
    MdiClipboardTextOutline ,
} from 'materialdesignicons-vue3/icons/';

const iconsMapping = {
    INVOICE: MdiReceiptTextOutline ,
    ORDER: MdiClipboardTextOutline ,
    CONTRACT: MdiFileDocumentEditOutline,
    STATEMENT: MdiTextBoxOutline,
};

export const getIconForDocType = (docType) => {
    return iconsMapping[docType] ? iconsMapping[docType] : MdiFileDocumentOutline;
};

export const listDocuments = async (filter = []) => {
    const res = await r({
        endpoint: `${keeper('apiUrl')}/v1/document/query`,
        sessionToken: keeper('sessionToken'),
        method: 'post',
        data: filter,
    });
    return res;
};

export const getDocument = async (documentId, docType = null, getContent = false) => {
    if (documentId !== '0') {
        return (
            (await r({
                endpoint:
                    `${keeper('apiUrl')}/v1/document/${documentId}?content=${getContent}` +
                    (docType ? '&doctype=' + docType : ''),
                sessionToken: keeper('sessionToken'),
                method: 'get',
            })) || {}
        );
    }
};

export const saveDocument = async (documentId, request) => {
    let method = 'put',
        endpoint = `${keeper('apiUrl')}/v1/document/${documentId}`;

    if (documentId === '0') {
        method = 'post';
        endpoint = `${keeper('apiUrl')}/v1/document`;
    }

    const res = await r({
        endpoint: endpoint,
        sessionToken: keeper('sessionToken'),
        method: method,
        data: request,
    });

    return res;
};

export const deactivateDocument = async (documentId) => {
    if (documentId !== '0') {
        return (
            (await r({
                endpoint: `${keeper('apiUrl')}/v1/document/${documentId}`,
                sessionToken: keeper('sessionToken'),
                method: 'delete',
            })) || {}
        );
    }
};

export const getDocumentTypes = async () => {
    const response = await r({
        endpoint: `${keeper('apiUrl')}/v1/document/types`,
        sessionToken: keeper('sessionToken'),
        method: 'get',
    });
    if (response) {
        return response.reduce((result, obj) => {
            const key = obj['name'];
            const value = obj['columns'].reduce((result2, obj2) => {
                const key2 = obj2['name'];
                const value2 = obj2['type'];
                result2[key2] = value2;
                return result2;
            }, {});
            result[key] = value;
            return result;
        }, {});
    }
};
export const getUrlFromBase64 = async (base64Content) => {
    const buffer = Buffer.from(base64Content, 'base64');
    const blob = new Blob([buffer], { type: 'application/pdf' });
    return URL.createObjectURL(blob);
};

export const useDocuments = () => {
    return {
        listDocuments: listDocuments,
        getDocument: getDocument,
        saveDocument: saveDocument,
        deactivateDocument: deactivateDocument,
        getUrlFromBase64: getUrlFromBase64,
        getDocumentTypes: getDocumentTypes,
        getIconForDocType: getIconForDocType,
    };
};

export const dropFileFromInput = (dataPack, fileInputId = '#dropzone-file') => {
    const file = document.querySelector(fileInputId).files[0];
    if (typeof file === 'object' && ['pdf'].includes(fileExtension(file.name).toLowerCase())) {
        const reader = new FileReader();
        reader.onloadend = () => {
            // Convert to Base64 string
            const base64 = reader.result;
            //console.log('useImage.js Loaded image from input', image.name, image.type);
            dataPack.name = file.name;
            dataPack.type = file.type;
            dataPack.file = base64.split(',')[1];
            dataPack.result = base64;
        };
        reader.readAsDataURL(file);
    }
};

export default useDocuments;
