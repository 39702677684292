import { t } from './i18n';

export const currentRouteMatch = (matchRoutes, routeName) => {
    return matchRoutes.includes(routeName);
};

export const metaFind = (routeName) => {
    const t = meta.filter((item) => {
        return item.matchRoutes.includes(routeName);
    }, routeName);
    return t.length > 0 ? t[0].tags : [];
};

const addTitleSuffix = (str) => {
    return `${str} &#128640 ${t('appName')}`;
};

export const meta = [
    {
        matchRoutes: ['dashboard'],
        tags: [
            {
                tag: 'title',
                params: {
                    innerHTML: addTitleSuffix(`${t('Dashboard')}`),
                },
            },
            {
                tag: 'meta',
                params: {
                    name: 'description',
                    content: 'App dashboard and stats',
                },
            },
        ],
    },
    {
        matchRoutes: ['documents'],
        tags: [
            {
                tag: 'title',
                params: {
                    innerHTML: addTitleSuffix(`${t('Documents')}`),
                },
            },
            {
                tag: 'meta',
                params: {
                    name: 'description',
                    content: 'Documents',
                },
            },
        ],
    },
    {
        matchRoutes: ['testing'],
        tags: [
            {
                tag: 'title',
                params: {
                    innerHTML: addTitleSuffix(`${t('Tester')}`),
                },
            },
            {
                tag: 'meta',
                params: {
                    name: 'description',
                    content: 'Tester view',
                },
            },
        ],
    },
    
    {
        matchRoutes: ['profile'],
        tags: [
            {
                tag: 'title',
                params: {
                    innerHTML: addTitleSuffix(`${t('Profile')}`),
                },
            },
            {
                tag: 'meta',
                params: {
                    name: 'description',
                    content: 'User profile',
                },
            },
        ],
    },
    {
        matchRoutes: ['users'],
        tags: [
            {
                tag: 'title',
                params: {
                    innerHTML: addTitleSuffix(`${t('Users')}`),
                },
            },
            {
                tag: 'meta',
                params: {
                    name: 'description',
                    content: 'View all users',
                },
            },
        ],
    },
    {
        matchRoutes: ['login'],
        tags: [
            {
                tag: 'title',
                params: {
                    innerHTML: addTitleSuffix(`${t('Login')}`),
                },
            },
            {
                tag: 'meta',
                params: {
                    name: 'description',
                    content: 'System login',
                },
            },
        ],
    },
    {
        matchRoutes: ['settings'],
        tags: [
            {
                tag: 'title',
                params: {
                    innerHTML: addTitleSuffix(`${t('Settings')}`),
                },
            },
            {
                tag: 'meta',
                params: {
                    name: 'description',
                    content: 'Client local settings',
                },
            },
        ],
    },
    
    {
        matchRoutes: ['ui-test'],
        tags: [
            {
                tag: 'title',
                params: {
                    innerHTML: addTitleSuffix(`${t('UITest')}`),
                },
            },
            {
                tag: 'meta',
                params: {
                    name: 'description',
                    content: 'SoftDM UI test',
                },
            },
        ],
    },
];

export const navs = () => {
    return {
        currentRouteMatch: currentRouteMatch,
        meta: meta,
        metaFind: metaFind,
    };
};

export default navs;
