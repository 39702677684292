// base & libs
import { createApp } from 'vue';
import { createWebHistory, createRouter } from 'vue-router';
import dayjs from 'dayjs';
import { notify } from 'notiwind';
import { t } from './composables/i18n';
import { load, sanitizeUrl } from './composables/useConfig';
import { isLoggedIn, getLoggedUser } from './composables/useLogin';
import { keeper } from './composables/utils';
import { TippyPlugin } from 'tippy.vue';
import Notifications from 'notiwind';
import VueClipboard from 'vue3-clipboard';
// app route components
import App from './App.vue';
import Dashboard from './pages/DashboardView.vue';

import Documents from './pages/DocumentsView.vue';

import Users from './pages/UsersView.vue';
import Profile from './pages/ProfileView.vue';
import Settings from './pages/SettingsView.vue';
import Login from './pages/LoginView.vue';
import UITest from './pages/UITestView.vue';


// base css
import './index.css';
// Themes
import { themeConfig } from './themes/default/theme';

const config = load();

if (typeof window.ENV === 'object') {
    config.env.name = window.ENV.name || 'DEVINT';
    config.env.docker = window.ENV.docker;
    config.env.hosts = [];
    for (let i in window.ENV.hosts) {
        config.env.hosts[i] = sanitizeUrl(window.ENV.hosts[i]);
    }
    config.env.apiUrl = sanitizeUrl(window.ENV.apiUrl);
}

keeper('env', config.env.name);
keeper('apiUrl', config.env.apiUrl);
keeper('requiredComplexity', config.env.requiredComplexity);
keeper('safe', config.keeper.safe);
keeper('db', config.keeper.safe ? 'sessionStorage' : 'localStorage');
keeper('imageMaxWidth', config.image.maxWidth);
keeper('imageMaxHeight', config.image.maxHeight);
keeper('tz', config.env.tz);
keeper('tzOffset', config.env.tzOffset);
keeper('docker', config.env.docker);

const routes = [
    {
        path: '/',
        component: Dashboard,
        name: 'dashboard',
        meta: { requiredRoles: config.roles.logged, transition: 'slide-left' },
    },
    {
        path: '/documents',
        component: Documents,
        name: 'documents',
        meta: { requiredRoles: config.roles.readers },
    },
    {
        path: '/users',
        component: Users,
        name: 'users',
        meta: { requiredRoles: config.roles.admins },
    },
    {
        path: '/profile/:userid',
        component: Profile,
        name: 'profile',
        meta: { requiredRoles: config.roles.logged },
    },
    {
        path: '/settings',
        component: Settings,
        name: 'settings',
        meta: { requiredRoles: config.roles.logged },
    },
    {
        path: '/login',
        component: Login,
        name: 'login',
        meta: { requiredRoles: config.roles.all },
    },
    {
        path: '/ui-test',
        component: UITest,
        name: 'ui-test',
        meta: { requiredRoles: config.roles.all },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const isAllowedByOverride = (routeName, id) => {
    if (!config.overrideSecurity.routes.includes(routeName)) {
        return true;
    }

    return (
        config.overrideSecurity.routes.includes(routeName) &&
        config.overrideSecurity.ids.includes(id)
    );
};

// Avoid frame embedding
if (location !== parent.location) {
    console.log('Frame detected & avoided');
    parent.location.href = 'https://www.softino.cz';
}

//router.beforeEach(async (to, from) => {
router.beforeEach(async (to) => {
    await isLoggedIn();
    const loggedUser = getLoggedUser();
    const requiredRoles = to.meta.requiredRoles || [];

    let routeAllowed = false;
    let loggedIn = loggedUser.id != '0' ? true : false;

    if (
        typeof requiredRoles === 'object' &&
        isAllowedByOverride(to.name, loggedUser.id) &&
        (requiredRoles.length === 0 || requiredRoles.includes(loggedUser.role))
    ) {
        routeAllowed = true;
    }

    // Do not set return on login/logout
    if (to.path !== '/login') {
        keeper('returnUrl', to.path);
    }

    // Continue, when everything is ok
    if (loggedIn === true) {
        if (to.path === '/login') {
            return '/';
        }

        if (routeAllowed === true) {
            return true;
        }

        notify(
            {
                group: 'warning',
                title: `${t('Sorry')}`,
                text: `${t('NoPermission', { path: to.path })}`,
            },
            5000,
        );
        //return '/';
        return false;
    }

    // Log not logged user and reroute to login
    if (to.path !== '/login' && routeAllowed === false && loggedIn === false) {
        return '/login';
    }
});

const app = createApp(App);

app.config.globalProperties.$dayjs = dayjs;
app.config.globalProperties.t = t;
app.config.globalProperties.keeper = keeper;
app.config.globalProperties.config = config;
app.config.globalProperties.theme = themeConfig();

app.use(TippyPlugin);
app.use(router);
app.use(Notifications);
app.use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
});
export const useGlobals = () => app.config.globalProperties;

app.mount('#app');
