<template>
    <Headify :data="headMeta" />

    <NavMainHorizontal />
    <div class="hidden md:block">
        <div
            class="fixed top-16 inset-x-0 z-4000 flex items-end justify-end bg-white h-8 xl:hidden shadow-sm"
        >
            <div class="flex-none h-8 flex gap-0 justify-center items-center p-0">
                <button
                    :class="[
                        panels.left.show === false ? '' : 'hidden',
                        `
                        ${theme.f('btn', {
                            state: 'transparent',
                            disabled: false,
                            size: 'lg',
                        })}
                    `,
                    ]"
                    @click="panelSwitch('left')"
                >
                    <MdiDotsHorizontal :class="theme.f('btnIcon', { size: 'lg' })" />
                </button>
            </div>
            <div class="grow h-8 bg-white flex gap-0 p-0"></div>
            <div
                v-if="Object.keys(documentDetailsConfig.selectedDocumentIdsWithType).length > 0"
                class="flex-none h-8 flex gap-0 justify-center items-center p-0"
            >
                <button
                    :class="[
                        panels.right.show === false ? '' : 'hidden',
                        `
                        ${theme.f('btn', {
                            state: 'transparent',
                            disabled: false,
                            size: 'lg',
                        })}
                    `,
                    ]"
                    @click="panelSwitch('right')"
                >
                    <MdiDotsHorizontal :class="theme.f('btnIcon', { size: 'lg' })" />
                </button>
            </div>
        </div>
    </div>

    <!-- Grid def -->
    <div class="grid grid-cols-1 md:grid-cols-12 gap-0 overflow-y-auto">
        <!-- Grid columns -->
        <!-- Left column -->
        <DocumentFilterComponent
            :document-typed-params="config.docTypedParams"
            :document-filter-params="docFilterConfig.documentFilterParams"
            :reset-filtered-documents="resetFilteredDocuments"
            :filter-documents-data="filterDocumentsData"
            :document-types="docFilterConfig.documentTypes"
            :document-details-config="documentDetailsConfig"
            :show-config="panels.left"
        />

        <!-- Center column -->
        <div
            :class="
                'bg-slate-50 col-span-1 md:col-span-12  flex flex-col h-screen max-h-screen xl:px-0 md:pt-24 xl:pt-16 ' +
                (panels.right.show
                    ? '2xl:col-span-6 xl:col-span-6'
                    : '2xl:col-span-9 xl:col-span-9')
            "
        >
            <DocumentTableComponent
                :document-details-config="documentDetailsConfig"
                :filter-data="filterData"
                :doc-filter-config="docFilterConfig"
                :filter-documents-data="filterDocumentsData"
                :select-document="selectDocument"
                :document-typed-params="config.docTypedParams"
                :list-key="docFilterConfig.listKey"
                :open-new-doc-editor="openNewDocEditor"
                :prepare-docs-for-deactivate="prepareDocsForDeactivate"
            />
        </div>

        <!-- Right column -->
        <div
            v-if="panels.right.show === true"
            :class="[
                panels.right.show === true ? 'md:fixed right-0' : 'md:fixed right-full',
                'bg-slate-50 lg:text-base text-sm transition-all ease-in-out duration-200 top-0 bottom-0 z-5000  md:w-2/6 md:max-w-2/6 grid-cols-none md:h-screen md:min-h-screen md:max-h-screen flex flex-col xl:static xl:top-auto xl:bottom-auto xl:right-auto col-span-1 md:col-span-3 xl:w-auto xl:max-w-auto md:pt-16 border-l-4 border-slate-100 xl:border-l-2 md:overflow-y-auto',
            ]"
        >
            <div class="relative hidden md:block">
                <button
                    v-tippy="t('buttonClose')"
                    type="button"
                    class="absolute top-0 left-0 rounded w-6 h-6 font-medium focus:outline-none text-gray-700 p-1"
                    @click="closeDocumentDetails"
                >
                    <MdiClose class="w-6 h-6 items-center justify-center" />
                </button>
            </div>

            <div class="pt-8 px-4">
                <div
                    v-if="
                        documentDetailsConfig.previewDocumentContent ||
                        (!documentDetailsConfig.previewDocumentContent &&
                            documentDetailsConfig.showPreview)
                    "
                >
                    <div class="flex pl-2 gap-2">
                        <div class="flex flex-wrap gap-2">
                            <div class="">
                                <button
                                    :class="
                                        theme.f('btn', {
                                            state: 'warning',
                                            disabled: false,
                                        })
                                    "
                                    @click="
                                        documentDetailsConfig.showPreview =
                                            !documentDetailsConfig.showPreview
                                    "
                                >
                                    <MdiFileFind :class="theme.f('btnIcon')" />
                                    {{
                                        t(
                                            documentDetailsConfig.showPreview
                                                ? 'documentPreviewHide'
                                                : 'documentPreview',
                                        )
                                    }}
                                </button>
                            </div>
                            <div class="">
                                <button
                                    :class="
                                        theme.f('btn', {
                                            state: 'lime',
                                            disabled: false,
                                        })
                                    "
                                    @click="
                                        downloadFile(
                                            documentDetailsConfig.previewDocumentContent,
                                            documentDetailsConfig.selectedDocumentFields.name,
                                        )
                                    "
                                >
                                    <MdiDownload :class="theme.f('btnIcon')" />
                                    {{ t('buttonDownload') }}
                                </button>
                            </div>
                            <div class="">
                                <button
                                    :class="
                                        theme.f('btn', {
                                            state: 'yellow',
                                            disabled: false,
                                        })
                                    "
                                    @click="openUpdateDocEditor"
                                >
                                    <MdiFileDocumentPlusOutline :class="theme.f('btnIcon')" />
                                    {{ t('buttonNewVersion') }}
                                </button>
                            </div>
                            <div v-if="!documentDetailsConfig.showDocVersions">
                                <button
                                    :class="
                                        theme.f('btn', {
                                            state: 'teal',
                                            disabled: false,
                                        })
                                    "
                                    @click="
                                        filterDocumentVersions(
                                            documentDetailsConfig.selectedDocumentFields.versionsId,
                                            documentDetailsConfig.selectedDocumentFields.docType,
                                        )
                                    "
                                >
                                    <MdiViewList :class="theme.f('btnIcon')" />
                                    {{ t('documentShowVersions') }}
                                </button>
                            </div>
                            <div v-if="documentDetailsConfig.editorMode === false">
                                <button
                                    :class="
                                        theme.f('btn', {
                                            state: 'info',
                                            disabled:
                                                documentDetailsConfig.selectedDocumentFields
                                                    .locked === true,
                                        })
                                    "
                                    :disabled="
                                        documentDetailsConfig.selectedDocumentFields.locked === true
                                    "
                                    @click="documentDetailsConfig.editorMode = true"
                                >
                                    <MdiPencil :class="theme.f('btnIcon')" />
                                    {{ t('documentEdit') }}
                                </button>
                            </div>
                            <div v-if="documentDetailsConfig.editorMode === true">
                                <button
                                    :class="
                                        theme.f('btn', {
                                            state: 'primary',
                                            disabled:
                                                documentDetailsConfig.selectedDocumentFields
                                                    .locked === true,
                                        })
                                    "
                                    :disabled="
                                        documentDetailsConfig.selectedDocumentFields.locked === true
                                    "
                                    @click="
                                        saveDocumentData(
                                            documentDetailsConfig.selectedDocumentFields,
                                        )
                                    "
                                >
                                    <MdiContentSave :class="theme.f('btnIcon')" />
                                    {{ t('buttonSave') }}
                                </button>
                            </div>

                            <div>
                                <button
                                    :class="
                                        theme.f('btn', {
                                            state: 'danger',
                                            disabled:
                                                documentDetailsConfig.selectedDocumentFields
                                                    .deleted === true ||
                                                documentDetailsConfig.selectedDocumentFields
                                                    .locked === true,
                                        })
                                    "
                                    :disabled="
                                        documentDetailsConfig.selectedDocumentFields.deleted ===
                                            true ||
                                        documentDetailsConfig.selectedDocumentFields.locked === true
                                    "
                                    @click="
                                        prepareDocsForDeactivate([
                                            documentDetailsConfig.selectedDocumentFields.documentId,
                                        ])
                                    "
                                >
                                    <MdiDelete :class="theme.f('btnIcon')" />
                                    {{ t('documentDeactivate') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="font-bold text-center pt-3 pb-4">
                    {{ t('DocumentParameters') }}
                </div>
                <div class="www pb-8 flex pl-4">
                    <label
                        for="show-all-params"
                        class="block lg:text-sm text-xs select-none font-bold"
                    >
                        {{ t('showAllParams') }}
                    </label>
                    <div class="flex pl-2 items-center">
                        <input
                            id="show-all-params"
                            v-model="documentDetailsConfig.showAllParams"
                            type="checkbox"
                            name="show-all-params"
                            autocomplete="off"
                            class="border rounded-md shadow-sm focus:outline-none lg:text-sm text-xs border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                            @change="selectParams"
                        />
                    </div>
                </div>

                <div
                    v-if="
                        documentDetailsConfig.selectedDocumentFields.attributes &&
                        documentDetailsConfig.selectedDocumentFields.attributes.length > 0
                    "
                    class="www flex pl-4"
                >
                    <label
                        for="show-custom-params"
                        class="block lg:text-sm text-xs select-none font-bold"
                    >
                        {{ t('showCustomParams') }}
                    </label>
                    <div class="flex pl-2 items-center">
                        <input
                            id="show-custom-params"
                            v-model="documentDetailsConfig.showCustomParams"
                            type="checkbox"
                            name="show-custom-params"
                            autocomplete="off"
                            class="border rounded-md shadow-sm focus:outline-none lg:text-sm text-xs border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                            @change="selectParams"
                        />
                    </div>
                </div>
            </div>

            <div class="overflow-y-auto px-8 pt-8">
                <div v-for="(item, index) in documentDetailsConfig.paramsToShow" :key="`${item}`">
                    <div class="flex py-2 items-center">
                        <div class="block py-1 lg:text-sm text-xs font-semibold w-24 lg:w-40">
                            {{ t(item) }} :
                        </div>
                        <div class="pl-2">
                            <p
                                v-if="config.docTypedParams[item] === 'docType'"
                                class="flex lg:text-sm text-xs text-gray-700 italic break-all"
                            >
                                <component
                                    :is="
                                        getIconForDocType(
                                            documentDetailsConfig.selectedDocumentFields[item],
                                        )
                                    "
                                    :class="theme.f('btnIcon', { size: 'lg' })"
                                />
                                {{ t(documentDetailsConfig.selectedDocumentFields[item]) }}
                            </p>
                            <p
                                v-if="
                                    config.docTypedParams[item] !== 'boolean' &&
                                    config.docTypedParams[item] !== 'docType' &&
                                    (!config.docEditableParams.includes(item) ||
                                        !documentDetailsConfig.editorMode)
                                "
                                class="lg:text-sm text-xs break-all text-gray-700 italic"
                            >
                                {{ documentDetailsConfig.selectedDocumentFields[item] }}
                            </p>

                            <input
                                v-if="
                                    documentDetailsConfig.editorMode === true &&
                                    config.docEditableParams.includes(item)
                                "
                                :id="`${item}`"
                                v-model="documentDetailsConfig.selectedDocumentFields[item]"
                                type="text"
                                :name="`${item}`"
                                autocomplete="off"
                                :disabled="!config.docEditableParams.includes(item)"
                                class="border rounded-md shadow-sm focus:outline-none lg:text-sm text-xs border-gray-300 focus:ring-blue-500 focus:border-blue-500 min-w-[255px]"
                            />
                            <input
                                v-if="config.docTypedParams[item] === 'boolean'"
                                :id="`${item}`"
                                v-model="documentDetailsConfig.selectedDocumentFields[item]"
                                type="checkbox"
                                :name="`${item}`"
                                autocomplete="off"
                                disabled="true"
                                class="border rounded-md shadow-sm focus:outline-none lg:text-sm text-xs border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                    </div>
                </div>
                <div
                    v-if="
                        documentDetailsConfig.showCustomParams &&
                        documentDetailsConfig.selectedDocumentFields.attributes.length > 0
                    "
                    class="mx-8 my-2 border-t-2 border-slate-300"
                ></div>
                <div v-if="documentDetailsConfig.showCustomParams">
                    <div
                        v-for="(item, index) in documentDetailsConfig.selectedDocumentFields
                            .attributes"
                        :key="`${item}`"
                    >
                        <div class="flex py-2 items-center">
                            <label class="block py-1 lg:text-sm text-xs font-semibold w-24 lg:w-40">
                                {{ t(item.name) }} :
                            </label>
                            <div class="pl-2">
                                <p
                                    v-if="!documentDetailsConfig.editorMode"
                                    class="flex lg:text-sm text-xs break-all text-gray-700 italic"
                                >
                                    {{ item.value }}
                                </p>
                                <div v-if="documentDetailsConfig.editorMode" class="min-w-[255px]">
                                    <div
                                        v-if="
                                            docFilterConfig.documentTypes[
                                                documentDetailsConfig.selectedDocumentFields.docType
                                            ][item.name] === 'TEXT' ||
                                            docFilterConfig.documentTypes[
                                                documentDetailsConfig.selectedDocumentFields.docType
                                            ][item.name] === 'INT'
                                        "
                                        class="flex-1"
                                    >
                                        <input
                                            v-if="documentDetailsConfig.editorMode"
                                            :id="`${item}`"
                                            v-model="item.value"
                                            :type="
                                                getInputType(
                                                    docFilterConfig.documentTypes[
                                                        documentDetailsConfig.selectedDocumentFields
                                                            .docType
                                                    ][item.name],
                                                )
                                            "
                                            :name="`${item}`"
                                            autocomplete="off"
                                            class="border rounded-md shadow-sm focus:outline-none lg:text-sm text-xs border-gray-300 focus:ring-blue-500 focus:border-blue-500 w-full"
                                        />
                                    </div>

                                    <div
                                        v-if="
                                            docFilterConfig.documentTypes[
                                                documentDetailsConfig.selectedDocumentFields.docType
                                            ][item.name] === 'DATETIME'
                                        "
                                    >
                                        <VueDatePicker
                                            v-model="item.value"
                                            format="yyyy-MM-dd HH:mm:SSS"
                                            enable-seconds
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <DocumentEditDialogComponent
        :key="editDocumentDialogKey"
        :state="editDocumentDialogState"
        :post-action="postCreateDocumentAction"
        :post-action-after-close="postCreateDocumentActionAfter"
        :doc-update-data="docUpdateData"
        :document-types="docFilterConfig.documentTypes"
    />

    <ModalPrompt
        :state="confirmModal.state"
        :buttons="confirmModal.buttons"
        :icon="confirmModal.icon"
    />
</template>

<script setup>
import NavMainHorizontal from '../components/NavMainHorizontalComponent.vue';
import { reactive, ref } from 'vue';
import { useRoute } from 'vue-router';
import { t } from '../composables/i18n';
import { cloneData } from '../composables/utils';
import {
    listDocuments,
    getDocument,
    saveDocument,
    deactivateDocument,
    getUrlFromBase64,
    getDocumentTypes,
    getIconForDocType,
} from '../composables/useDocuments';
import { getInputType } from '../composables/docUtils';
import { getKeywordForCondition, getKeywordForStringCondition } from '../composables/useFilter';
import Headify from '../components/HeadifyComponent.vue';
import navs from '../composables/navs';

import {
    MdiContentSave,
    MdiClose,
    MdiListStatus,
    MdiViewList,
    MdiFileDocumentPlusOutline,
    MdiFileFind,
    MdiDownload,
    MdiDelete,
    MdiPencil,
    MdiDotsHorizontal,
} from 'materialdesignicons-vue3/icons/';
import { getRndKey } from '../composables/utils';
import DocumentEditDialogComponent from '../components/DocumentEditDialogComponent.vue';
import DocumentFilterComponent from '../components/DocumentFilterComponent.vue';

import DocumentTableComponent from '../components/DocumentTableComponent.vue';
import dayjs from 'dayjs';
import { notify } from 'notiwind';
import ModalPrompt from '../components/ModalPromptComponent.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import { load } from '../composables/useConfig';

const panels = reactive({
    main: {
        show: false,
    },
    left: {
        show: false,
    },
    right: {
        show: false,
    },
});

const panelSwitch = (ident) => {
    panels[ident].show = panels[ident].show === true ? false : true;
};

const config = load(),
    route = useRoute(),
    headMeta = navs().metaFind(route.name),
    documentsData = reactive(
        (await listDocuments({ filter: [], paging: { page: 1, records: 10 } })) || [],
    );

const documentDetailsConfig = reactive({
    selectedDocumentIdsWithType: {},
    selectedDocumentIndex: -1,
    selectedDocumentFields: {},
    showPreview: false,
    previewDocumentContent: null,
    editorMode: false,
    showAllParams: false,
    paramsToShow: config.docBaseParams,
    showCustomParams: false,
    showDocVersions: false,
    docIdsToDelete: [],
});

const confirmModal = reactive({
    state: {
        theme: 'danger',
        open: false,
        title: t('docDeactivationConfirmationTitle'),
        text: t('docDeactivationConfirmationText'),
        data: {},
    },
    icon: MdiListStatus,
    buttons: [
        {
            title: t('documentDeactivate'),
            action: () => {
                console.log('deactivateDocs');
                deactivateDocs();
            },
            state: 'danger',
        },
    ],
});

const filterData = { obj: reactive(cloneData(documentsData)) };
const docFilterConfig = reactive({
    documentFilterParams: {
        attributes: Object.keys(config.docTypedParams).reduce((acc, key) => {
            acc[key] = { value: null, condition: null };
            return acc;
        }, {}),
        ordering: null,
    },

    documentTypes: await getDocumentTypes(),
    listKey: getRndKey(),
    filterKey: getRndKey(),
    documentTableEditMode: false,
    currentPage: 1,
    attributesOld: null,
});

const editDocumentDialogKey = ref(getRndKey());
const editDocumentDialogState = reactive({
    open: false,
    isUpdate: false,
});
const docUpdateData = reactive({
    name: null,
    documentId: false,
});

const postCreateDocumentAction = async () => {
    filterDocumentsData(docFilterConfig.documentFilterParams);
};

const postCreateDocumentActionAfter = async (saveResult) => {
    if (saveResult) {
        notify(
            {
                group: 'success',
                title: 'Done',
                text: `Dokument ${saveResult.name} je úspěšně vytvořen`,
            },
            2500,
        );
    } else {
        console.log('deactivate unsuccesfull', saveResult);
        notify(
            {
                group: 'error',
                title: 'Error',
                text: `Chyba během vytvoření dokumentu ${saveResult.name}`,
            },
            5000,
        );
    }
};

const selectDocument = async (documentId, docType, docIndex) => {
    resetSelection();
    documentDetailsConfig.selectedDocumentIdsWithType[documentId] = docType;
    documentDetailsConfig.selectedDocumentIndex = docIndex;
    documentDetailsConfig.selectedDocumentFields = await getDocument(documentId, docType, true);
    if (documentDetailsConfig.selectedDocumentFields.contentBody.content) {
        documentDetailsConfig.previewDocumentContent = await getUrlFromBase64(
            documentDetailsConfig.selectedDocumentFields.contentBody.content,
        );
    } else {
        //document doesnt have content in db
        documentDetailsConfig.previewDocumentContent = null;
    }

    panels.right.show = true;
};

const resetSelection = () => {
    documentDetailsConfig.showCustomParams = false;
    documentDetailsConfig.showAllParams = false;
    documentDetailsConfig.paramsToShow = config.docBaseParams;
    documentDetailsConfig.selectedDocumentIdsWithType = {};
};

const closeDocumentDetails = async () => {
    panels.right.show = false;

    // documentDetailsConfig.selectedDocumentIdsWithType = [];
    // documentDetailsConfig.selectedDocumentIndex = -1;
    // documentDetailsConfig.showPreview = false;
};

const saveDocumentData = async (document) => {
    const request = {};
    request.docType = document.docType;
    config.docEditableParams.forEach((paramName) => {
        request[paramName] = document[paramName];
    });
    if (request.docType) {
        request.attributes.forEach((attribute) => {
            const paramType = docFilterConfig.documentTypes[request.docType][attribute.name];

            if (paramType === 'DATETIME') {
                if (attribute.value) {
                    attribute.value = dayjs(attribute.value).format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ');
                } else {
                    attribute.value = '';
                }
            }
        });
    }

    const saveResult = saveDocument(
        documentDetailsConfig.selectedDocumentFields.documentId,
        request,
    );
    if (saveResult) {
        config.docEditableParams.forEach((paramName) => {
            filterData.obj.documents[documentDetailsConfig.selectedDocumentIndex][paramName] =
                document[paramName];
        });
        notify(
            {
                group: 'success',
                title: 'Done',
                text: `Dokument ${document.name} je úspěšně uložen`,
            },
            2500,
        );
    } else {
        console.log('save unsuccesfull', saveResult);
        notify(
            {
                group: 'error',
                title: 'Error',
                text: `Chyba během vytvoření ukladání ${document.name}`,
            },
            5000,
        );
    }
    documentDetailsConfig.editorMode = false;
};

const deactivateDocs = () => {
    confirmModal.state.open = false;
    documentDetailsConfig.docIdsToDelete.forEach((docId) => {
        deactivateDoc(docId);
    });
    documentDetailsConfig.docIdsToDelete = [];
};
const prepareDocsForDeactivate = (documentIds) => {
    documentDetailsConfig.docIdsToDelete = documentIds;
    confirmModal.state.text =
        (documentDetailsConfig.docIdsToDelete.length > 1
            ? t('docDeactivationConfirmationMultipleText')
            : t('docDeactivationConfirmationText')) +
        ' ' +
        documentDetailsConfig.docIdsToDelete +
        '?';

    confirmModal.state.open = true;
};

const deactivateDoc = (documentId) => {
    const deactivateResult = deactivateDocument(documentId);
    if (deactivateResult) {
        documentDetailsConfig.selectedDocumentFields.deleted = true;
        notify(
            {
                group: 'success',
                title: 'Done',
                text: `Dokument ${documentId} je úspěšně deaktivován`,
            },
            2500,
        );
    } else {
        console.log('deactivate unsuccesfull', deactivateResult);
        notify(
            {
                group: 'error',
                title: 'Error',
                text: `Chyba během deaktivace dokumentu ${documentId}`,
            },
            5000,
        );
    }
};

const filterDocumentsData = async (documentFilterParams) => {
    const filterRequest = prepareFilterRequest(documentFilterParams);
    const result = await listDocuments(filterRequest);
    if (result) {
        filterData.obj = result;
    } else {
        filterData.obj.documents = [];
    }

    docFilterConfig.listKey = getRndKey();
};

const prepareFilterRequest = (documentFilterParams) => {
    const filterRequest = {};
    filterRequest.filter = [];

    Object.keys(documentFilterParams.attributes).forEach((key) => {
        if (documentFilterParams.attributes[key].value) {
            if (config.docTypedParams[key] === 'boolean') {
                filterRequest.filter.push({
                    attrType: 'BOOLEAN',
                    attrName: key,
                    attrFilterValue: documentFilterParams.attributes[key].value,
                    condition: 'EQUALS',
                });
            } else if (
                config.docTypedParams[key] === 'date' ||
                (documentFilterParams.attributes.docType.value &&
                    docFilterConfig.documentTypes[documentFilterParams.attributes.docType.value][
                        key
                    ] === 'DATETIME')
            ) {
                if (documentFilterParams.attributes[key].condition) {
                    filterRequest.filter.push({
                        attrType: 'DATETIME',
                        attrName: key,
                        attrFilterValue: dayjs(documentFilterParams.attributes[key].value).format(
                            'YYYY-MM-DDTHH:mm:ss.SSSSSSZ',
                        ),
                        condition: getKeywordForCondition(
                            documentFilterParams.attributes[key].condition,
                        ),
                    });
                }
            } else if (config.docTypedParams[key] === 'docType') {
                filterRequest.docType = documentFilterParams.attributes[key].value;
            } else if (
                key === 'documentId' ||
                key === 'versionsId' ||
                (documentFilterParams.attributes.docType.value &&
                    docFilterConfig.documentTypes[documentFilterParams.attributes.docType.value][
                        key
                    ] === 'INT')
            ) {
                filterRequest.filter.push({
                    attrType: 'NUMBER',
                    attrName: key,
                    attrFilterValue: documentFilterParams.attributes[key].value,
                    condition: getKeywordForStringCondition(
                        documentFilterParams.attributes[key].condition,
                    ),
                    caseSensitive: false,
                });
            } else {
                filterRequest.filter.push({
                    attrType: 'STRING',
                    attrName: key,
                    attrFilterValue: documentFilterParams.attributes[key].value,
                    condition: getKeywordForStringCondition(
                        documentFilterParams.attributes[key].condition,
                    ),
                    caseSensitive: false,
                });
            }
        }
    });

    if (documentFilterParams.ordering) {
        filterRequest.ordering = documentFilterParams.ordering;
    }
    filterRequest.paging = { page: docFilterConfig.currentPage, records: 10 };

    filterRequest.currentOnly = documentFilterParams.currentOnly;

    return filterRequest;
};

const resetFilteredDocuments = async () => {
    filterData.obj = await listDocuments({
        filter: [],
        paging: { page: docFilterConfig.currentPage, records: 10 },
    });

    docFilterConfig.listKey = getRndKey();
};

const openNewDocEditor = () => {
    editDocumentDialogState.open = true;
    editDocumentDialogState.isUpdate = false;
    editDocumentDialogKey.value = getRndKey();
};

const openUpdateDocEditor = () => {
    docUpdateData.name = documentDetailsConfig.selectedDocumentFields.name;
    docUpdateData.documentId = documentDetailsConfig.selectedDocumentFields.documentId;
    docUpdateData.docType = documentDetailsConfig.selectedDocumentFields.docType;
    editDocumentDialogState.open = true;
    editDocumentDialogState.isUpdate = true;
    editDocumentDialogKey.value = getRndKey();
};

const selectParams = () => {
    if (documentDetailsConfig.showAllParams) {
        documentDetailsConfig.paramsToShow = Object.keys(config.docTypedParams);
    } else {
        documentDetailsConfig.paramsToShow = config.docBaseParams;
    }
};

const downloadFile = async (dataUrl, fileName) => {
    const link = document.createElement('a');
    link.href = dataUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    console.log('downloadFile', fileName, dataUrl);
    link.click();
    link.remove();
};

const filterDocumentVersions = async (versionsId, docType) => {
    docFilterConfig.attributesOld = docFilterConfig.documentFilterParams.attributes;
    docFilterConfig.documentFilterParams.attributes = {};
    docFilterConfig.documentFilterParams.attributes.versionsId = {
        value: versionsId,
        condition: 'equals',
    };
    docFilterConfig.documentFilterParams.currentOnly = false;

    const filterRequest = {};
    filterRequest.docType = docType;
    filterRequest.filter = [];
    filterRequest.filter.push({
        attrType: 'NUMBER',
        attrName: 'versionsId',
        attrFilterValue: versionsId,
        condition: 'EQUALS',
    });
    docFilterConfig.currentPage = 1;
    filterRequest.paging = { page: docFilterConfig.currentPage, records: 10 };
    filterRequest.currentOnly = false;
    if (docFilterConfig.documentFilterParams.ordering) {
        filterRequest.ordering = docFilterConfig.documentFilterParams.ordering;
    }
    const result = await listDocuments(filterRequest);
    if (result) {
        filterData.obj = result;
    } else {
        filterData.obj.documents = [];
    }
    documentDetailsConfig.showDocVersions = true;
    docFilterConfig.listKey = getRndKey();
    docFilterConfig.filterKey = getRndKey();
};
</script>
