<template>
    <div
        v-clipboard:copy="copyText"
        v-clipboard:success="copyHandlerSuccess"
        v-clipboard:error="copyHandlerError"
        class="flex-inline cursor-help text-slate-100 text-[0.6rem] p-0 rounded-sm"
        :title="t('copyToClipboard')"
    >
        <div
            :class="[
                config.env.name.startsWith('DEV') ? 'bg-rose-700' : 'bg-indigo-700',
                'inline py-1 pl-2 pr-1 rounded-l-md',
            ]"
        >
            <span>{{ config.env.name }}</span> /
            <span v-if="config.env.docker">Docker</span>
            <span v-else>Native</span>
        </div>
        <div class="inline bg-green-600 py-1 px-1">{{ config.version }}</div>
        <div class="inline bg-cyan-700 py-1 pl-1 pr-2 rounded-r-md">
            {{ config.env.tz }} UTC+{{ -config.env.tzOffset / 60 }}
        </div>
    </div>
</template>

<script setup>
import { notify } from 'notiwind';
import { useGlobals } from '../main.js';
//import { copy } from '../composables/useClip';
import { t } from '../composables/i18n';

defineExpose({
    name: 'BuildBadge',
});

const globals = useGlobals();

const copyText = `${globals.config.env.name} / ${globals.config.env.docker ? 'Docker' : 'Native'} ${
    globals.config.version
} ${globals.config.env.tz} UTC+${-globals.config.env.tzOffset / 60}`.trim();

const copyHandlerSuccess = async () => {
    notify(
        {
            group: 'info',
            title: t('copiedToClipboard'),
            text: copyText,
        },
        1770,
    );
};
const copyHandlerError = async () => {
    notify(
        {
            group: 'error',
            title: t('Error'),
            text: copyText,
        },
        2500,
    );
};
</script>
