export const load = () => {
    const _config = {
        name: 'SoftDM UI',
        description: 'Softino document management application frontend',
        version: '0.0.1',
        keeper: {
            safe: false,
        },
        logLevel: ['console'], // 'console', 'log'
        roles: {
            any: [],
            all: ['NONE', 'READER', 'EXTSYS', 'WRITER', 'VALIDATOR', 'ADMIN'],
            logged: ['READER', 'EXTSYS', 'WRITER', 'VALIDATOR', 'ADMIN'],
            readers: ['READER', 'WRITER', 'VALIDATOR', 'ADMIN'],
            writers: ['WRITER', 'VALIDATOR', 'ADMIN'],
            validators: ['VALIDATOR', 'ADMIN'],
            admins: ['ADMIN'],
        },
        overrideSecurity: {
            routes: [],
            ids: [],
        },
        docEditableParams: ['name', 'title', 'description', 'attributes'],
        docTypedParams: {
            name: 'string',
            docType: 'docType',
            version: 'string',
            description: 'string',
            comment: 'string',
            creator: 'string',
            documentId: 'string',
            versionsId: 'string',
            parentId: 'string',
            current: 'boolean',
            contentSize: 'string',
            title: 'string',
            extension: 'string',
            mimeType: 'string',
            owner: 'string',
            modifier: 'string',
            creationDate: 'date',
            modifyDate: 'date',
            locked: 'boolean',
            deleted: 'boolean',
            lockedBy: 'string',
        },
        docBaseParams: ['name', 'docType', 'version', 'description', 'comment', 'creator'],

        rank: {
            NONE: 0,
            READER: 100,
            EXTSYS: 200,
            WRITER: 300,
            VALIDATOR: 400,
            ADMIN: 1000,
        },
        image: {
            maxWidth: 1024,
            maxHeight: 1024,
        },
        cleanTablePolicy: false,
        paginator: {
            limit: 10,
        },
        environments: {
            // prod: {
            //     hosts: ['templatesfeprod.creditas.cz'],
            //     apiUrl: 'https://templatesapp.creditas.cz',
            //     fileStorageApiUrl: 'https://file-storage-crdbe.softino.cloud',
            //     fileStorageBaseUrl: 'https://public-file-storage.softino.cloud',
            //     requiredComplexity: 3,
            // },
            // pre: {
            //     hosts: ['templatesfepre.creditas.cz'],
            //     apiUrl: 'https://templatespre.creditas.cz',
            //     fileStorageApiUrl: 'https://file-storage-crdbe.softino.cloud',
            //     fileStorageBaseUrl: 'https://public-file-storage.softino.cloud',
            //     requiredComplexity: 3,
            // },
            // uat: {
            //     hosts: ['templatesfeuat.creditas.cz'],
            //     apiUrl: 'https://templatesuat.creditas.cz',
            //     fileStorageApiUrl: 'https://file-storage-crdbe.softino.cloud',
            //     fileStorageBaseUrl: 'https://public-file-storage.softino.cloud',
            //     requiredComplexity: 3,
            // },
            // sit: {
            //     hosts: ['templatesfesit.creditas.cz'],
            //     apiUrl: 'https://templatessit.creditas.cz',
            //     fileStorageApiUrl: 'https://file-storage-crdbe.softino.cloud',
            //     fileStorageBaseUrl: 'https://public-file-storage.softino.cloud',
            //     requiredComplexity: 3,
            // },
            dev: {
                hosts: ['templating-crdfe.softino.cloud'],
                apiUrl: 'https://templating-crdbe.softino.cloud',
                fileStorageApiUrl: 'https://file-storage-crdbe.softino.cloud',
                fileStorageBaseUrl: 'https://public-file-storage.softino.cloud',
                requiredComplexity: 3,
            },
            localbe: {
                hosts: ['softdm-ui.test'],
                apiUrl: 'https://templating-crdbe.softino.cloud',
                fileStorageApiUrl: 'https://file-storage-crdbe.softino.cloud',
                fileStorageBaseUrl: 'https://public-file-storage.softino.cloud',
                requiredComplexity: 2,
            },
            localdev: {
                hosts: ['localhost', 'softdm-ui-local.test'],
                apiUrl: 'http://localhost:8080',
                //apiUrl: 'https://templating-crdbe.softino.cloud',
                //apiUrl: 'https://templating-crdbatch.softino.cloud',
                fileStorageApiUrl: 'https://file-storage-crdbe.softino.cloud',
                fileStorageBaseUrl: 'https://public-file-storage.softino.cloud',
                requiredComplexity: 1,
            },
        },
        env: {},
    };

    _config.env = startEnv(_config.environments, getHostname());
    if (Intl) {
        const dt = new Date();
        _config.env.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        _config.env.tzOffset = dt.getTimezoneOffset();
        _config.env.docker = false;
    }
    return _config;
};

export const sanitizeUrl = (str) => {
    return str.endsWith('/') ? str.slice(0, -1) : str;
};
export const startEnv = (environments, hostname) => {
    let _last = '',
        _env = {};
    for (let index in environments) {
        if (environments[index].hosts.length > 0 && environments[index].hosts.includes(hostname)) {
            _env = environments[index];
            _env.name = index;
            _env.host = hostname;
            return _env;
        } else if (environments[index].hosts.length === 0) {
            _last = index;
        }
    }

    if (_last !== '') {
        _env = environments[_last];
        _env.name = _last;
        _env.host = _env.hosts[0];
    }

    return _env;
};

export const getHostname = () => {
    return window.location.hostname;
};

export const useConfig = () => {
    return {
        load: load,
        sanitizeUrl: sanitizeUrl,
        startEnv: startEnv,
        getHostname: getHostname,
    };
};

export default useConfig;
