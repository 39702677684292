export const models = () => {
    return {
        '/v1/user': {
            methods: {
                get: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        204: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
                post: {
                    schema: {
                        typeName: {
                            default: 'UpdateUserRequest',
                            type: 'string',
                        },
                        firstName: {
                            minLength: 2,
                            type: 'string',
                        },
                        lastName: {
                            minLength: 2,
                            type: 'string',
                        },
                        userName: {
                            minLength: 2,
                            type: 'string',
                        },
                        email: {
                            format: 'email',
                            type: 'string',
                        },
                        password: {
                            format: 'password',
                            type: 'string',
                        },
                    },
                    required: ['typeName'],
                    responses: {
                        201: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
            },
        },

        '/v1/user/{userId}': {
            methods: {
                delete: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                        404: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
                get: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                        404: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
                patch: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        400: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                        404: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
                put: {
                    schema: {
                        typeName: {
                            default: 'UpdateUserRequest',
                            type: 'string',
                        },
                        firstName: {
                            minLength: 2,
                            type: 'string',
                        },
                        lastName: {
                            minLength: 2,
                            type: 'string',
                        },
                        userName: {
                            minLength: 2,
                            type: 'string',
                        },
                        email: {
                            format: 'email',
                            type: 'string',
                        },
                        password: {
                            format: 'password',
                            type: 'string',
                        },
                    },
                    required: ['typeName'],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        400: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                        404: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
            },
        },

        '/v1/login': {
            methods: {
                delete: {
                    schema: {},
                    required: [],
                    responses: {
                        204: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
                post: {
                    schema: {
                        userName: {
                            description: 'Username or Email',
                            type: 'string',
                        },
                        password: {
                            type: 'string',
                        },
                    },
                    required: ['password', 'userName'],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        404: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
                put: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
            },
        },

        '/v1/login/status': {
            methods: {
                get: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
            },
        },

        '/v1/template': {
            methods: {
                get: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {
                                response: {
                                    properties: {},
                                    required: [],
                                },
                                extension: {
                                    properties: {},
                                    required: [],
                                },
                            },
                        },
                        204: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
                post: {
                    schema: {
                        typeName: {
                            default: 'UpdateTemplateRequest',
                            type: 'string',
                        },
                        code: {
                            type: 'string',
                        },
                        name: {
                            type: 'string',
                        },
                        type: {
                            properties: {},
                            required: [],
                        },
                        description: {
                            type: 'string',
                        },
                    },
                    required: ['typeName'],
                    responses: {
                        201: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
            },
        },

        '/v1/template/{templateId}': {
            methods: {
                delete: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                        404: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
                get: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                        404: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
                put: {
                    schema: {
                        typeName: {
                            default: 'UpdateTemplateRequest',
                            type: 'string',
                        },
                        code: {
                            type: 'string',
                        },
                        name: {
                            type: 'string',
                        },
                        type: {
                            properties: {},
                            required: [],
                        },
                        description: {
                            type: 'string',
                        },
                    },
                    required: ['typeName'],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                        404: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
            },
        },

        '/v1/templateVersion': {
            methods: {
                get: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        204: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
                post: {
                    schema: {
                        typeName: {
                            default: 'UpdateTemplateVersionRequest',
                            type: 'string',
                        },
                        templateId: {
                            properties: {},
                            required: [],
                        },
                        langCode: {
                            type: 'string',
                        },
                        version: {
                            type: 'string',
                        },
                        dmsId: {
                            type: 'string',
                        },
                        subject: {
                            description: 'Message subject (email subject, inbox subject, etc.)',
                            type: 'string',
                        },
                        source: {
                            type: 'string',
                        },
                        sourceInline: {
                            type: 'string',
                        },
                        changeLog: {
                            type: 'string',
                        },
                        state: {
                            properties: {},
                            required: [],
                        },
                        uiConfig: {
                            type: 'string',
                        },
                    },
                    required: ['version'],
                    responses: {
                        201: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
            },
        },

        '/v1/templateVersion/{templateVersionId}': {
            methods: {
                delete: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                        404: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
                get: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                        404: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
                put: {
                    schema: {
                        typeName: {
                            default: 'UpdateTemplateVersionRequest',
                            type: 'string',
                        },
                        templateId: {
                            properties: {},
                            required: [],
                        },
                        langCode: {
                            type: 'string',
                        },
                        version: {
                            type: 'string',
                        },
                        dmsId: {
                            type: 'string',
                        },
                        subject: {
                            description: 'Message subject (email subject, inbox subject, etc.)',
                            type: 'string',
                        },
                        source: {
                            type: 'string',
                        },
                        sourceInline: {
                            type: 'string',
                        },
                        changeLog: {
                            type: 'string',
                        },
                        state: {
                            properties: {},
                            required: [],
                        },
                        uiConfig: {
                            type: 'string',
                        },
                    },
                    required: ['version'],
                    responses: {
                        201: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                        404: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
            },
        },

        '/v1/placeholder/{placeholderId}': {
            methods: {
                delete: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
                get: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
                put: {
                    schema: {
                        name: {
                            type: 'string',
                        },
                        uiConfig: {
                            type: 'string',
                        },
                        description: {
                            type: 'string',
                        },
                        model: {
                            items: {
                                type: 'string',
                            },
                            type: 'array',
                        },
                        sample: {
                            properties: {},
                            required: [],
                        },
                    },
                    required: ['name'],
                    responses: {
                        201: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
            },
        },

        '/v1/placeholder': {
            methods: {
                get: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        204: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
                post: {
                    schema: {
                        name: {
                            type: 'string',
                        },
                        uiConfig: {
                            type: 'string',
                        },
                        description: {
                            type: 'string',
                        },
                        model: {
                            items: {
                                type: 'string',
                            },
                            type: 'array',
                        },
                        sample: {
                            properties: {},
                            required: [],
                        },
                    },
                    required: ['name'],
                    responses: {
                        201: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
            },
        },

        '/v1/placeholderRef/{placeholderRefId}': {
            methods: {
                delete: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
                get: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
                put: {
                    schema: {
                        templateVersionId: {
                            properties: {},
                            required: [],
                        },
                        placeholderDefinitionId: {
                            properties: {},
                            required: [],
                        },
                        isRequired: {
                            type: 'boolean',
                        },
                    },
                    required: ['isRequired', 'placeholderDefinitionId', 'templateVersionId'],
                    responses: {
                        201: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
            },
        },

        '/v1/placeholderRef': {
            methods: {
                get: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        204: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
                post: {
                    schema: {
                        templateVersionId: {
                            properties: {},
                            required: [],
                        },
                        placeholderDefinitionId: {
                            properties: {},
                            required: [],
                        },
                        isRequired: {
                            type: 'boolean',
                        },
                    },
                    required: ['isRequired', 'placeholderDefinitionId', 'templateVersionId'],
                    responses: {
                        201: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
            },
        },

        '/v1/external/multiDocument': {
            methods: {
                post: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {
                                documentId: {
                                    type: 'string',
                                },
                                dmsId: {
                                    type: 'string',
                                },
                                duid: {
                                    type: 'string',
                                },
                                status: {
                                    properties: {},
                                    required: [],
                                },
                                message: {
                                    items: {
                                        type: 'string',
                                    },
                                    type: 'array',
                                },
                                output: {
                                    properties: {
                                        fileName: {
                                            type: 'string',
                                        },
                                        fileSize: {
                                            format: 'int32',
                                            type: 'integer',
                                        },
                                        contentType: {
                                            type: 'string',
                                        },
                                        content: {
                                            format: 'byte',
                                            type: 'string',
                                        },
                                    },
                                    required: [],
                                },
                            },
                        },
                        400: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
            },
        },

        '/v1/external/multiDocument/validate': {
            methods: {
                post: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {
                                templateCode: {
                                    type: 'string',
                                },
                                duid: {
                                    type: 'string',
                                },
                                status: {
                                    properties: {},
                                    required: [],
                                },
                                message: {
                                    type: 'string',
                                },
                            },
                        },
                        400: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
            },
        },

        '/v1/external/multiDocument/zip': {
            methods: {
                post: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
            },
        },

        '/v2/external/document': {
            methods: {
                post: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        400: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
            },
        },

        '/v1/ping': {
            methods: {
                get: {
                    schema: {},
                    required: [],
                    responses: {
                        204: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
            },
        },

        '/v1/snippet': {
            methods: {
                get: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        204: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
                post: {
                    schema: {
                        type: {
                            type: 'string',
                        },
                        source: {
                            type: 'string',
                        },
                        name: {
                            type: 'string',
                        },
                    },
                    required: [],
                    responses: {
                        201: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
            },
        },

        '/v1/snippet/{snippetId}': {
            methods: {
                delete: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                        404: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
                get: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                        404: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
                put: {
                    schema: {
                        type: {
                            type: 'string',
                        },
                        source: {
                            type: 'string',
                        },
                        name: {
                            type: 'string',
                        },
                    },
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                        404: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
            },
        },

        '/v1/tag': {
            methods: {
                get: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        204: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
                post: {
                    schema: {
                        name: {
                            type: 'string',
                        },
                        description: {
                            type: 'string',
                        },
                    },
                    required: [],
                    responses: {
                        201: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
            },
        },

        '/v1/tag/{tagId}': {
            methods: {
                delete: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                        404: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
                get: {
                    schema: {},
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                        404: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
                put: {
                    schema: {
                        name: {
                            type: 'string',
                        },
                        description: {
                            type: 'string',
                        },
                    },
                    required: [],
                    responses: {
                        200: {
                            type: 'plain',
                            schema: {},
                        },
                        401: {
                            type: 'plain',
                            schema: {},
                        },
                        403: {
                            type: 'plain',
                            schema: {},
                        },
                        404: {
                            type: 'plain',
                            schema: {},
                        },
                    },
                },
            },
        },
    };
};

export default models;
