<template>
    <Headify :data="headMeta" />

    <div class="bg-white min-h-screen">
        <NavMainHorizontal :key="route.name" />

        <div class="flex flex-1 flex-col pt-16">
            <main class="flex-1">
                <div class="w-full mx-auto max-w-7xl px-2 sm:px-4 pt-12 md:pt-4">
                    <div class="py-2">
                        <Breadcrumbs :links="breadcrumbsLinks" />
                    </div>

                    <div class="py-6 space-y-6">
                        <div class="www py-2 sm:p-4">
                            <label for="language" class="block text-sm font-medium text-gray-700">
                                {{ t('language') }}
                            </label>
                            <select
                                id="role"
                                v-model="localSettings.language"
                                name="role"
                                class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            >
                                <option v-for="code in langsAvailable" :key="code" :value="code">
                                    {{ t(code) }}
                                </option>
                            </select>
                        </div>

                        <div class="www py-2 px-1 sm:p-4 text-right">
                            <button
                                :class="
                                    theme.f('btn', {
                                        state: 'primary',
                                    })
                                "
                                @click="saveSettings(localSettings)"
                            >
                                <MdiContentSave :class="theme.f('btnIcon')" />
                                {{ t('buttonSave') }}
                            </button>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { t, lang } from '../composables/i18n';
import { keeper } from '../composables/utils';
import Headify from '../components/HeadifyComponent.vue';
import navs from '../composables/navs';
import Breadcrumbs from '../components/BreadcrumbsComponent.vue';
import NavMainHorizontal from '../components/NavMainHorizontalComponent.vue';
import { MdiContentSave } from 'materialdesignicons-vue3/icons/';

const route = useRoute();
const langsAvailable = ['cs', 'en'];
const localSettings = {
    language: lang,
    editorShowBlocks: true,
};

const saveSettings = (localSettings) => {
    for (let setting in localSettings) {
        keeper(setting, localSettings[setting]);
    }
    document.location.href = route.path;
};

const breadcrumbsLinks = [
    {
        name: 'dashboard',
        title: t('Dashboard'),
    },
    {
        name: 'settings',
        title: t('Settings'),
        final: true,
    },
];

const headMeta = navs().metaFind(route.name);
</script>
