const config = {
    default:
        'transition-colors rounded-md border border-transparent rounded-md justify-center items-center font-normal',
    icon: {
        xs: 'inline w-3 h-3 -mt-1 mr-0.5 sm:w-4 sm:h-4',
        sm: 'inline w-3 h-3 -mt-1 mr-0.5 sm:w-4 sm:h-4',
        md: 'inline w-3 h-3 -mt-1 mr-0.5 sm:w-4 sm:h-4 md:w-5 md:h-5',
        lg: 'inline w-3 h-3 -mt-1 mr-0.5 sm:w-4 sm:h-4 md:w-5 md:h-5 lg:w-6 lg:h-6',
        xl: 'inline w-3 h-3 -mt-1 mr-0.5 sm:w-4 sm:h-4 md:w-5 md:h-5 lg:w-6 lg:h-6',
    },
    size: {
        xs: 'text-xs py-0.5 px-1.5',
        sm: 'text-xs sm:text-sm py-0.5 px-1.5 sm:py-1 sm:px-2',
        md: 'text-xs sm:text-sm md:text-base py-2 px-3 sm:py-3 sm:px-4',
        lg: 'text-xs sm:text-sm md:text-lg py-2 px-3 sm:py-4 sm:px-6',
        xl: 'text-xs sm:text-sm md:text-xl py-2 px-3 sm:py-4 sm:px-6',
    },
    state: {
        brand: 'bg-gradient-to-tr from-emerald-100 via-sky-100 to-blue-100 text-sky-600',
        promo: 'bg-indigo-100 text-indigo-600',
        primary: 'bg-blue-100 text-blue-600',
        info: 'bg-cyan-100 text-cyan-600',
        success: 'bg-emerald-100 text-emerald-600',
        warning: 'bg-amber-100 text-amber-600',
        danger: 'bg-red-100 text-red-600',
        neutral: 'bg-neutral-100 text-neutral-500',
    },
};

config.factory = {
    alert: (cfg = {}) => {
        const _state = cfg.state || 'neutral';
        const src = [
            `-tpl-state-${_state} -tpl-theme-alert`,
            config.default,
            cfg.size && Object.keys(config.size).includes(cfg.size)
                ? config.size[cfg.size]
                : config.size['md'],
            config.state[cfg.state || 'neutral'],
        ];
        return src.join(' ');
    },
    alertIcon: (cfg = { size: 'sm' }) => {
        return cfg.size && Object.keys(config.icon).includes(cfg.size)
            ? config.icon[cfg.size]
            : config.icon['sm'];
    },
};

export const alert = config;
export default alert;
