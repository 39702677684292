<template>
    <TransitionRoot as="template" :show="state.open">
        <Dialog as="div" class="relative z-5000" :open="state.open === true" @close="dialogClose()">
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed z-10 inset-0 overflow-y-auto">
                <div
                    class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0"
                >
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 scale-0"
                        enter-to="opacity-100 translate-y-0 scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 scale-0"
                    >
                        <DialogPanel
                            class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-2xl sm:w-full sm:p-6"
                        >
                            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                <button
                                    type="button"
                                    class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-none"
                                    @click="dialogClose()"
                                >
                                    <span class="sr-only">Close</span>
                                    <MdiClose class="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            <div class="sm:flex sm:items-start">
                                <div
                                    class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-200 sm:mx-0 sm:h-10 sm:w-10"
                                >
                                    <MdiTooltipPlus
                                        v-if="documentId === '0'"
                                        class="h-6 w-6 text-gray-600"
                                        aria-hidden="true"
                                    />
                                    <MdiReceiptTextEdit
                                        v-if="documentId !== '0'"
                                        class="h-6 w-6 text-gray-600"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div
                                    class="sm:flex-1 mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left"
                                >
                                    <DialogTitle
                                        as="h3"
                                        class="text-lg leading-6 font-medium text-gray-900 pt-2 select-none"
                                    >
                                        {{
                                            state.isUpdate
                                                ? t('newVersion')
                                                : t('buttonNewDocument')
                                        }}
                                    </DialogTitle>

                                    <div class="www pt-6 pb-2 select-none">
                                        <label
                                            for="document-name"
                                            class="block text-sm font-medium text-gray-700"
                                        >
                                            {{ t('documentName') }}
                                        </label>
                                        <input
                                            id="document-name"
                                            v-model="newDocument.name"
                                            type="text"
                                            name="document-name"
                                            autocomplete="off"
                                            class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm"
                                            :class="
                                                newDocument.name.length === 0
                                                    ? 'border-red-300 focus:ring-blue-500 focus:border-blue-500'
                                                    : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
                                            "
                                        />
                                    </div>
                                    <div class="www pt-6 pb-2 select-none">
                                        <label
                                            for="document-title"
                                            class="block text-sm font-medium text-gray-700"
                                        >
                                            {{ t('documentTitle') }}
                                        </label>
                                        <input
                                            id="document-title"
                                            v-model="newDocument.title"
                                            type="text"
                                            name="document-title"
                                            autocomplete="off"
                                            :readonly="documentId !== '0'"
                                            class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        />
                                    </div>
                                    <div class="www pt-6 pb-2 select-none">
                                        <label
                                            for="document-description"
                                            class="block text-sm font-medium text-gray-700"
                                        >
                                            {{ t('documentDescription') }}
                                        </label>
                                        <input
                                            id="document-description"
                                            v-model="newDocument.description"
                                            type="text"
                                            name="document-description"
                                            autocomplete="off"
                                            class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        />
                                    </div>
                                    <div class="www pt-6 pb-2 select-none">
                                        <label
                                            for="document-comment"
                                            class="block text-sm font-medium text-gray-700"
                                        >
                                            {{ t('comment') }}
                                        </label>
                                        <input
                                            id="document-comment"
                                            v-model="newDocument.comment"
                                            type="text"
                                            name="document-comment"
                                            autocomplete="off"
                                            class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        />
                                    </div>
                                    <div v-if="state.isUpdate">
                                        <div class="www pt-6 pb-2 select-none">
                                            <label
                                                for="version-selector"
                                                class="block text-sm font-medium text-gray-700"
                                            >
                                                {{ t('version') }}
                                            </label>
                                            <select
                                                id="version-selector"
                                                v-model="newDocument.version"
                                                name="version-selector"
                                                class="bg-white border rounded-md shadow-sm focus:outline-none sm:text-sm w-full"
                                                :class="
                                                    !newDocument.version
                                                        ? 'border-red-300 focus:ring-blue-500 focus:border-blue-500'
                                                        : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
                                                "
                                            >
                                                <option
                                                    v-for="version in Object.values(versionList)"
                                                    :key="version"
                                                    :value="version"
                                                >
                                                    {{ version }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="www pt-6 pb-2 select-none flex items-center">
                                            <label
                                                for="is-current-version"
                                                class="block text-sm font-medium text-gray-700"
                                            >
                                                {{ t('current') }}
                                            </label>
                                            <div class="pl-2 block">
                                                <input
                                                    id="is-current-version"
                                                    v-model="newDocument.current"
                                                    type="checkbox"
                                                    name="is-current-version"
                                                    autocomplete="off"
                                                    class="border rounded-md shadow-sm focus:outline-none sm:text-sm border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="www pt-6 pb-2 select-none">
                                        <label
                                            for="document-description"
                                            class="block text-sm font-medium text-gray-700"
                                        >
                                            {{ t('docType') }}
                                        </label>
                                        <select
                                            id="document-type"
                                            v-model="newDocument.docType"
                                            name="document-type"
                                            class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm"
                                            :class="
                                                newDocument.docType
                                                    ? 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
                                                    : 'border-red-300 focus:ring-blue-500 focus:border-blue-500'
                                            "
                                            @change="selectDocType"
                                        >
                                            <option
                                                v-for="docType in Object.keys(documentTypes)"
                                                :key="docType"
                                                :value="docType"
                                            >
                                                {{ t(docType) }}
                                            </option>
                                        </select>
                                    </div>
                                    <div v-if="newDocument.attributes.length > 0">
                                        <div
                                            v-for="(item, index) in newDocument.attributes"
                                            :key="`${item}`"
                                        >
                                            <div class="www pt-6 pb-2 select-none">
                                                <label
                                                    :for="`${item}`"
                                                    class="block text-sm font-medium text-gray-700"
                                                >
                                                    {{ t(item.name) }}
                                                </label>
                                                <div
                                                    v-if="
                                                        documentTypes[newDocument.docType][
                                                            item.name
                                                        ] === 'TEXT' ||
                                                        documentTypes[newDocument.docType][
                                                            item.name
                                                        ] === 'INT'
                                                    "
                                                >
                                                    <input
                                                        :id="`${item}`"
                                                        v-model="item.value"
                                                        type="text"
                                                        :name="`${item}`"
                                                        autocomplete="off"
                                                        class="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                                    />
                                                </div>

                                                <div
                                                    v-if="
                                                        documentTypes[newDocument.docType][
                                                            item.name
                                                        ] === 'DATETIME'
                                                    "
                                                >
                                                    <VueDatePicker
                                                        v-model="item.value"
                                                        format="yyyy-MM-dd HH:mm:SSS"
                                                        enable-seconds
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="flex flex-1 mt-4 border border-dashed border-gray-300"
                                    >
                                        <div
                                            id="editor-wrapper"
                                            class="flex-1 h-full col-span-10 p-4"
                                            @dragover.prevent="setActive"
                                            @dragenter.prevent="setActive"
                                            @dragleave.prevent="setInactive"
                                            @drop.prevent="handleDrop"
                                        >
                                            <div
                                                v-if="validResult()"
                                                class="w-full flex items-center justify-center"
                                            >
                                                <MdiFileDocumentOutline class="w-32 h-32" />
                                                <div class="truncate">{{ dataPack.name }}</div>
                                            </div>
                                            <div
                                                v-if="!validResult()"
                                                class="w-full flex flex-col items-center justify-center cursor-pointer"
                                                @click="chooseFile()"
                                            >
                                                <MdiCloudUpload
                                                    class="w-24 h-24 text-gray-200 hover:text-gray-300"
                                                />
                                                <div>
                                                    {{
                                                        t(
                                                            dataPack.stateBeforeDrop
                                                                ? 'dragBeforeDropTitle'
                                                                : 'dragAndDropTitle',
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="dropzone-file hidden">
                                            <input
                                                id="dropzone-file"
                                                type="file"
                                                class="hidden"
                                                @change="dropFileFromInput(dataPack)"
                                            />
                                        </div>

                                        <div v-if="dataPack.file !== ''" class="relative">
                                            <button
                                                v-tippy="t('removeDoc')"
                                                type="button"
                                                class="absolute top-0 right-4 rounded w-6 h-6 font-medium focus:outline-none text-gray-700 p-2"
                                                @click="clearDocument"
                                            >
                                                <MdiClose
                                                    class="md:w-6 md:h-6 items-center justify-center"
                                                />
                                            </button>
                                        </div>
                                    </div>

                                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse pb-5">
                                        <button
                                            type="button"
                                            :class="
                                                theme.f('btn', {
                                                    state: 'primary',
                                                    disabled:
                                                        newDocument.name.length === 0 ||
                                                        !newDocument.docType ||
                                                        !dataPack.file ||
                                                        (state.isUpdate && !newDocument.version),
                                                })
                                            "
                                            :disabled="
                                                newDocument.name.length === 0 ||
                                                !newDocument.docType ||
                                                !dataPack.file
                                            "
                                            @click="save(newDocument)"
                                        >
                                            <MdiContentSave
                                                :class="theme.f('btnIcon')"
                                                aria-hidden="true"
                                            />
                                            {{ t('buttonSave') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>

    <Spinner
        v-if="saving.active"
        type="complex"
        :color="`text-blue-600`"
        :color2="`text-blue-400`"
        :text-color="`text-gray-200`"
        :overlay="true"
        :speed="1000"
        :dots="true"
        :dot-char="'.'"
        :dot-dual="false"
        :dot-reverse="false"
        :text="t('loading')"
        :inline="false"
        :pulse="true"
    />
</template>

<script setup>
import { reactive, ref } from 'vue';
import { t } from '../composables/i18n';
import { saveDocument } from '../composables/useDocuments';
import Spinner from '../components/SpinnerComponent.vue';

import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import {
    MdiTooltipPlus,
    MdiClose,
    MdiContentSave,
    MdiReceiptTextEdit,
    MdiCloudUpload,
    MdiFileDocumentOutline,
} from 'materialdesignicons-vue3/icons/';

import { dropFileFromInput } from '../composables/useDocuments';
import { cloneData } from '../composables/utils';
import dayjs from 'dayjs';
import { notify } from 'notiwind';
import VueDatePicker from '@vuepic/vue-datepicker';

defineExpose({
    name: 'DocumentTypeDialog',
});

const props = defineProps({
    state: {
        type: Object,
        required: true,
    },
    postAction: {
        type: Function,
        required: true,
    },
    postActionAfterClose: {
        type: Function,
        required: true,
    },
    docUpdateData: {
        type: Object,
        required: true,
    },
    documentTypes: {
        type: Object,
        required: true,
    },
});

const dataPack = reactive({
    file: '',
    name: '',
    type: '',
    result: '',
    stateBeforeDrop: false,
    timeout: null,
});

const versionList = {
    major: 'MAJOR',
    minor: 'MINOR',
    same: 'SAME',
};

const state = props.state;
const docUpdateData = props.docUpdateData;
const documentTypes = props.documentTypes;

const documentId = ref('0');
const newDocument = reactive({
    id: state.isUpdate ? docUpdateData.documentId : '0',
    name: state.isUpdate ? docUpdateData.name : '',
    title: '',
    description: '',
    comment: '',
    version: '',
    current: false,
    docType: state.isUpdate ? docUpdateData.docType : null,
    attributes: getCustomAttributes(),
});

const saving = reactive({
    active: false,
    text: '',
});

const save = async (newDocument) => {
    const documentCreateRequest = cloneData(newDocument);
    documentCreateRequest.attributes.forEach((attribute) => {
        const paramType = documentTypes[newDocument.docType][attribute.name];
        if (paramType === 'DATETIME') {
            if (attribute.value) {
                attribute.value = dayjs(attribute.value).format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ');
            } else {
                attribute.value = '';
            }
        }
    });

    documentCreateRequest.contentBody = {};

    documentCreateRequest.contentBody.content = dataPack.file;
    if (newDocument.id === '0') {
        documentCreateRequest.contentBody.version = versionList.major;
    } else {
        documentCreateRequest.contentBody.version = newDocument.version;
        documentCreateRequest.contentBody.comment = newDocument.comment;
        documentCreateRequest.contentBody.current = newDocument.current;
    }

    const saveResult = await saveDocument(newDocument.id, documentCreateRequest);

    props.postAction();

    dialogClose();

    if (saveResult) {
        notify(
            {
                group: 'success',
                title: 'Done',
                text: `Dokument ${documentCreateRequest.name} je úspěšně vytvořen`,
            },
            2500,
        );
    } else {
        console.log('deactivate unsuccesfull', saveResult);
        notify(
            {
                group: 'error',
                title: 'Error',
                text: `Chyba během vytvoření dokumentu ${documentCreateRequest.name}`,
            },
            5000,
        );
    }
};

const dialogClose = () => {
    state.open = false;
};

const validResult = () => {
    return typeof dataPack.result === 'string' && dataPack.result.length > 10;
};

const chooseFile = function () {
    document.querySelector('#dropzone-file').click();
};

const selectDocType = () => {
    if (newDocument.docType && documentTypes[newDocument.docType]) {
        const customFields = {
            ...documentTypes[newDocument.docType],
        };
        console.log('selectDocType', customFields);
        const customAttrs = Object.keys(customFields).map((paramName) => ({
            name: paramName,
            value: '',
        }));
        newDocument.attributes = customAttrs;
    }
};

function getCustomAttributes() {
    if (state.isUpdate && docUpdateData.docType && documentTypes[docUpdateData.docType]) {
        const customFields = {
            ...documentTypes[docUpdateData.docType],
        };
        const result = [];

        Object.keys(customFields).forEach((paramName) => {
            result.push({ name: paramName, value: '' });
        });
        return result;
    }
    return [];
}

const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;

    const file = files[0];
    if (typeof file === 'object') {
        const reader = new FileReader();
        reader.onloadend = () => {
            // Convert to Base64 string
            const base64 = reader.result;
            //console.log('useImage.js Loaded image from input', image.name, image.type);
            dataPack.name = file.name;
            dataPack.type = file.type;
            dataPack.file = base64.split(',')[1];
            dataPack.result = base64;
        };
        reader.readAsDataURL(file);
    } else {
        console.log('Wrong document');
    }
};

const setActive = () => {
    dataPack.stateBeforeDrop = true;
    clearTimeout(dataPack.timeout);
};

const setInactive = () => {
    dataPack.timeout = setTimeout(() => {
        dataPack.stateBeforeDrop = false;
    }, 50);
};

const clearDocument = () => {
    dataPack.name = '';
    dataPack.type = '';
    dataPack.file = '';
    dataPack.result = '';
    dataPack.stateBeforeDrop = false;
};
</script>
<style lang="scss">
.preview {
    width: 100%;
    height: 100%;
    max-height: 200px;
    overflow: hidden;
}
</style>
