const config = {
    default:
        'transition-colors select-none rounded-md border border-transparent rounded-md inline-flex justify-center items-center',
    size: {
        xs: 'font-medium text-base shadow-sm py-2 px-4',
        sm: 'font-medium text-base shadow-sm py-2 px-4',
        md: 'font-medium text-base shadow-sm py-2 px-4',
        lg: 'font-medium text-lg shadow-sm py-3 px-6',
        xl: 'font-medium text-xl shadow-sm py-4 px-8',
    },
    icon: {
        xs: 'w-6 h-6 mr-1',
        sm: 'w-6 h-6 mr-1',
        md: 'w-6 h-6 mr-1',
        lg: 'w-7 h-7 mr-1',
        xl: 'w-8 h-8 mr-1',
    },
    state: {
        primary: 'bg-blue-700 text-white',
        info: 'bg-cyan-700 text-white',
        success: 'bg-emerald-700 text-white',
        warning: 'bg-amber-700 text-white',
        danger: 'bg-red-700 text-white',
        neutral: 'bg-neutral-700 text-white',
    },
};

config.factory = {
    modal: (cfg = {}) => {
        const src = [
            modal.default,
            modal.size[cfg.size || 'md'],
            modal.state[cfg.state || 'neutral'],
        ];
        return src.join(' ');
    },
    modalIcon: (cfg = {}) => {
        return modal.icon[cfg.size || 'md'];
    },
};

export const modal = config;
export default modal;
