<template>
    <div class="flex flex-col pt-8 pl-4 md:pl-8">
        <div class="flex gap-2 relative flex-wrap">
            <button
                :class="
                    theme.f('btn', {
                        state: 'success',
                        disabled: false,
                    })
                "
                @click="openNewDocEditor(t('buttonNewDocument'))"
            >
                <MdiPlus :class="theme.f('btnIcon')" />
                {{ t('buttonNew') }}
            </button>
            <div v-if="!docSelectorConfig.enabled" class="flex">
                <button
                    :class="
                        theme.f('btn', {
                            state: 'rose',
                            disabled: false,
                        })
                    "
                    @click="startDocSelection"
                >
                    <MdiCursorDefault :class="theme.f('btnIcon')" />
                    {{ t('buttonSelectDocs') }}
                </button>
            </div>

            <button
                v-if="docSelectorConfig.enabled"
                :class="
                    theme.f('btn', {
                        state: 'rose',
                        disabled: false,
                    })
                "
                @click="endDocSelection"
            >
                <MdiCloseOutline :class="theme.f('btnIcon')" />
                {{ t('buttonSelectDocsEnd') }}
            </button>

            <button
                v-if="docSelectorConfig.enabled"
                :class="
                    theme.f('btn', {
                        state: 'lime',
                        disabled:
                            Object.keys(documentDetailsConfig.selectedDocumentIdsWithType)
                                .length === 0,
                    })
                "
                :disabled="
                    Object.keys(documentDetailsConfig.selectedDocumentIdsWithType).length === 0
                "
                @click="batchFileDownload()"
            >
                <MdiDownload :class="theme.f('btnIcon')" />
                {{ t('buttonDownload') }}
            </button>

            <button
                v-if="docSelectorConfig.enabled"
                :class="
                    theme.f('btn', {
                        state: 'danger',

                        disabled:
                            Object.keys(documentDetailsConfig.selectedDocumentIdsWithType)
                                .length === 0,
                    })
                "
                :disabled="
                    Object.keys(documentDetailsConfig.selectedDocumentIdsWithType).length === 0
                "
                @click="batchFileDeactivate()"
            >
                <MdiDelete :class="theme.f('btnIcon')" />
                {{ t('documentDeactivate') }}
            </button>

            <div class="flex">
                <button
                    :class="
                        theme.f('btn', {
                            state: 'indigo',
                            disabled: false,
                        })
                    "
                    @click="
                        docFilterConfig.documentTableEditMode =
                            !docFilterConfig.documentTableEditMode
                    "
                >
                    <MdiTextBoxEditOutline :class="theme.f('btnIcon')" />
                    {{
                        t(
                            docFilterConfig.documentTableEditMode
                                ? 'buttonSaveTable'
                                : 'buttonEditTable',
                        )
                    }}
                </button>
            </div>
            <div v-if="docFilterConfig.documentTableEditMode" class="flex items-center gap-2">
                <label
                    for="new-table-column"
                    class="block text-xs md:text-sm font-medium text-gray-700"
                >
                    {{ t('newColumn') }}
                </label>
                <select
                    id="new-table-column"
                    v-model="docSortConfig.newColumn"
                    name="new-table-column"
                    class="bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-xs md:text-sm"
                    @change="addColumnToTable"
                >
                    <option
                        v-for="docType in getAddableAttributes()"
                        :key="docType"
                        :value="docType"
                    >
                        {{ t(docType) }}
                    </option>
                </select>
            </div>

            <div
                v-if="
                    docFilterConfig.documentTableEditMode &&
                    documentFilterParams.attributes.docType.value &&
                    documentFilterParams.attributes.docType.value !== 'DOCUMENT'
                "
                class="flex items-center gap-2"
            >
                <label
                    for="new-custom-table-column"
                    class="block text-xs md:text-sm font-medium text-gray-700"
                >
                    {{ t('newCustomColumn') }}
                </label>
                <select
                    id="new-custom-table-column"
                    v-model="docSortConfig.newCustomColumn"
                    name="new-custom-table-column"
                    class="bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-xs md:text-sm"
                    @change="addCustomColumnToTable"
                >
                    <option
                        v-for="customAttr in getAddableCustomAttributes()"
                        :key="customAttr"
                        :value="customAttr"
                    >
                        {{ t(customAttr) }}
                    </option>
                </select>
            </div>
        </div>
        <div class="flex-1 relative">
            <button
                v-if="documentDetailsConfig.showPreview === true"
                v-tippy="t('previewClose')"
                type="button"
                class="absolute top-0 right-2 rounded w-6 h-6 font-medium focus:outline-none text-gray-700 p-1"
                @click="documentDetailsConfig.showPreview = false"
            >
                <MdiClose class="md:w-6 md:h-6 items-center justify-center" />
            </button>
        </div>
    </div>

    <div
        class="overflow-y-auto"
        :class="
            documentDetailsConfig.showPreview === true ? 'flex flex-1 flex-col ' : 'flex flex-col'
        "
    >
        <div class="flex flex-1 grow">
            <div
                v-if="filterData.obj.documents.length === 0"
                class="flex-1 max-w-3xl m-8"
                :class="theme.f('alert', { state: 'warning' })"
            >
                <div class="font-medium py-1">
                    <MdiInformation :class="theme.f('alertIcon')" />
                    {{ t('Documents') }}
                </div>
                <p class="py-1 text-sm">{{ t('notFoundText') }}</p>
            </div>

            <div
                class="pt-12 md:pt-4"
                :class="documentDetailsConfig.showPreview === true ? 'hidden md:block' : ''"
            >
                <div :key="listKey" class="">
                    <div v-if="filterData.obj.documents.length > 0">
                        <div class="">
                            <div class="min-w-0 px-4 flex gap-10">
                                <div
                                    v-for="(row, index) in getTableRows()"
                                    :key="`${row}`"
                                    :class="getFlexClass(row)"
                                >
                                    <div
                                        class="flex font-bold py-1 cursor-pointer select-none justify-center"
                                        @click="sortRow(row)"
                                    >
                                        <div class="flex-none">
                                            {{ t(row) }}
                                        </div>
                                        <div class="flex">
                                            <MdiArrowDown
                                                v-if="
                                                    docSortConfig.column === row &&
                                                    docSortConfig.direction === 'asc'
                                                "
                                                class="h-5 w-5 text-slate-600"
                                            />
                                            <MdiArrowUp
                                                v-if="
                                                    docSortConfig.column === row &&
                                                    docSortConfig.direction === 'desc'
                                                "
                                                class="h-5 w-5 text-slate-600"
                                            />
                                            <div
                                                v-if="docFilterConfig.documentTableEditMode"
                                                @click.stop="removeColumnFromTable(index)"
                                            >
                                                <MdiClose class="h-5 w-5 text-red-600" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <ul
                            v-if="filterData.obj.documents.length > 0"
                            role="list"
                            class="divide-y divide-gray-200"
                        >
                            <template
                                v-for="(document, docIndex) in filterData.obj.documents"
                                :key="document.documentId"
                            >
                                <li>
                                    <div
                                        :class="
                                            Object.keys(
                                                documentDetailsConfig.selectedDocumentIdsWithType,
                                            ).includes(document.documentId)
                                                ? 'block  bg-slate-200'
                                                : 'block hover:bg-slate-50'
                                        "
                                        @click="
                                            processDocSelection(
                                                document.documentId,
                                                document.docType,
                                                docIndex,
                                            )
                                        "
                                    >
                                        <div class="flex py-4">
                                            <div class="flex-1">
                                                <div class="px-4 flex gap-10">
                                                    <div
                                                        v-for="(row, index) in getTableRows()"
                                                        :key="`${row}`"
                                                        :class="getFlexClass(row)"
                                                    >
                                                        <div
                                                            v-if="
                                                                documentTypedParams[row] ===
                                                                'string'
                                                            "
                                                            class="flex text-sm text-slate-600 justify-center"
                                                            :class="
                                                                row === 'name'
                                                                    ? 'font-semibold'
                                                                    : ''
                                                            "
                                                        >
                                                            {{ document[row] }}
                                                        </div>
                                                        <div
                                                            v-if="
                                                                documentTypedParams[row] ===
                                                                'boolean'
                                                            "
                                                            class="flex items-center text-sm text-slate-600 justify-center"
                                                            :class="
                                                                row === 'name'
                                                                    ? 'font-semibold'
                                                                    : ''
                                                            "
                                                        >
                                                            <input
                                                                :id="`document[row]`"
                                                                v-model="document[row]"
                                                                type="checkbox"
                                                                :name="`document[row]`"
                                                                autocomplete="off"
                                                                disabled="true"
                                                                class="border rounded-md shadow-sm focus:outline-none sm:text-sm border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                                            />
                                                        </div>

                                                        <div v-if="row === 'docType'">
                                                            <p
                                                                class="flex justify-center text-xs text-gray-900"
                                                            >
                                                                <component
                                                                    :is="
                                                                        getIconForDocType(
                                                                            document.docType,
                                                                        )
                                                                    "
                                                                    v-tippy="t(document.docType)"
                                                                    :class="
                                                                        theme.f('btnIcon', {
                                                                            size: 'lg',
                                                                        })
                                                                    "
                                                                />
                                                            </p>
                                                        </div>
                                                        <div
                                                            v-if="
                                                                documentTypedParams[row] === 'date'
                                                            "
                                                        >
                                                            <p
                                                                v-if="document[row]"
                                                                class="flex justify-center text-xs text-gray-900"
                                                            >
                                                                <time :datetime="document[row]">
                                                                    {{
                                                                        $dayjs(
                                                                            document[row],
                                                                        ).format('D. M. YYYY HH:mm')
                                                                    }}
                                                                </time>
                                                            </p>
                                                        </div>
                                                        <!-- Custom attributes -->
                                                        <div
                                                            v-if="
                                                                getCustAttrsForSelectedDocType()[
                                                                    row
                                                                ] === 'DATETIME'
                                                            "
                                                        >
                                                            <p
                                                                v-if="
                                                                    filterCustomAttr(document, row)
                                                                "
                                                                class="flex justify-center text-xs text-gray-900"
                                                            >
                                                                <time
                                                                    :datetime="
                                                                        filterCustomAttr(
                                                                            document,
                                                                            row,
                                                                        )
                                                                    "
                                                                >
                                                                    {{
                                                                        $dayjs(
                                                                            filterCustomAttr(
                                                                                document,
                                                                                row,
                                                                            ),
                                                                        ).format('D. M. YYYY HH:mm')
                                                                    }}
                                                                </time>
                                                            </p>
                                                        </div>
                                                        <div
                                                            v-if="
                                                                getCustAttrsForSelectedDocType()[
                                                                    row
                                                                ] === 'TEXT' ||
                                                                getCustAttrsForSelectedDocType()[
                                                                    row
                                                                ] === 'INT'
                                                            "
                                                            class="flex text-sm text-slate-600 justify-center"
                                                            :class="
                                                                row === 'name'
                                                                    ? 'font-semibold'
                                                                    : ''
                                                            "
                                                        >
                                                            {{ filterCustomAttr(document, row) }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </div>
            <div
                v-if="documentDetailsConfig.showPreview === true"
                class="flex flex-1 pt-4 md:pt-12"
            >
                <object
                    v-if="documentDetailsConfig.previewDocumentContent"
                    id="document-preview"
                    class="w-full"
                    :data="documentDetailsConfig.previewDocumentContent"
                ></object>
                <div
                    v-if="!documentDetailsConfig.previewDocumentContent"
                    class="flex w-full items-center justify-center"
                >
                    <p class="pb-96 text-4xl">{{ t('noPreviewAvailabe') }}</p>
                </div>
            </div>
        </div>
        <div v-if="documentDetailsConfig.showDocVersions" class="p-8">
            <button
                :class="`${theme.f('btn', {
                    state: 'neutral',
                })} mr-2`"
                @click="resetDocVersions"
            >
                <MdiChevronLeft :class="theme.f('btnIcon')" />
                {{ t('documentBackToFilter') }}
            </button>
        </div>
    </div>

    <div
        v-if="filterData.obj.documents.length > 0"
        class="flex items-center justify-center text-xs md:text-base"
    >
        {{ t('page') }}
        <div v-for="(pageNum, index) in getPagesCount()" :key="`${pageNum}`">
            <div
                class="p-1 cursor-pointer select-none"
                :class="docFilterConfig.currentPage === pageNum ? 'font-bold' : ''"
                @click="selectPage(pageNum)"
            >
                {{ pageNum }}
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive } from 'vue';
import { t } from '../composables/i18n';
import {
    MdiDownload,
    MdiDelete,
    MdiClose,
    MdiTextBoxEditOutline,
    MdiInformation,
    MdiArrowDown,
    MdiArrowUp,
    MdiChevronLeft,
    MdiPlus,
    MdiCursorDefault,
    MdiCloseOutline,
} from 'materialdesignicons-vue3/icons/';

import { getIconForDocType, getDocument, getUrlFromBase64 } from '../composables/useDocuments';

const props = defineProps({
    filterData: {
        type: Object,
        required: true,
    },
    documentDetailsConfig: {
        type: Object,
        required: true,
    },
    selectDocument: {
        type: Function,
        required: true,
    },
    filterDocumentsData: {
        type: Function,
        required: true,
    },
    openNewDocEditor: {
        type: Function,
        required: true,
    },
    docFilterConfig: {
        type: Object,
        required: true,
    },
    documentTypedParams: {
        type: Object,
        required: true,
    },
    listKey: {
        type: String,
        required: true,
    },

    prepareDocsForDeactivate: {
        type: Function,
        required: true,
    },
});

const filterData = props.filterData;
const documentDetailsConfig = props.documentDetailsConfig;
const documentFilterParams = props.docFilterConfig.documentFilterParams;
const docFilterConfig = props.docFilterConfig;
const documentTypedParams = props.documentTypedParams;
const listKey = props.listKey;

const docSortConfig = reactive({
    direction: 'none',
    column: null,
    tableRows: ['name', 'docType', 'version', 'creationDate', 'modifyDate'],
    newColumn: null,
    newCustomColumn: null,
    currentPageNumber: 1,
});

const docSelectorConfig = reactive({
    enabled: false,
    lastSelection: {},
});

const sortRow = (columnName) => {
    if (columnName === docSortConfig.column) {
        if (docSortConfig.direction === 'none') {
            docSortConfig.direction = 'asc';
        } else if (docSortConfig.direction === 'asc') {
            docSortConfig.direction = 'desc';
        } else {
            docSortConfig.direction = 'none';
        }
    } else {
        docSortConfig.column = columnName;
        docSortConfig.direction = 'asc';
    }
    if (docSortConfig.direction === 'none') {
        documentFilterParams.ordering = null;
    } else {
        documentFilterParams.ordering = {};
        documentFilterParams.ordering.orderBy = columnName;
        documentFilterParams.ordering.asc = docSortConfig.direction === 'asc' ? true : false;
    }
    props.filterDocumentsData(documentFilterParams);
};

const removeColumnFromTable = (columnIndex) => {
    docSortConfig.tableRows.splice(columnIndex, 1);
};

const addColumnToTable = () => {
    if (!docSortConfig.tableRows.includes(docSortConfig.newColumn)) {
        docSortConfig.tableRows.push(docSortConfig.newColumn);
    }
};
const addCustomColumnToTable = () => {
    if (!docSortConfig.tableRows.includes(docSortConfig.newCustomColumn)) {
        docSortConfig.tableRows.push(docSortConfig.newCustomColumn);
    }
};

const getAddableAttributes = () => {
    return Object.keys(documentTypedParams).filter((param) => {
        return !docSortConfig.tableRows.includes(param);
    });
};

const getCustAttrsForSelectedDocType = function () {
    if (documentFilterParams.attributes.docType && documentFilterParams.attributes.docType.value) {
        return docFilterConfig.documentTypes[documentFilterParams.attributes.docType.value];
    } else {
        return [];
    }
};

const filterCustomAttr = function (document, attrName) {
    if (document.attributes) {
        const attr = document.attributes.filter((attr) => {
            return attr.name === attrName;
        });

        if (attr.length > 0) {
            return attr[0].value;
        }
    }
};

const getAddableCustomAttributes = () => {
    if (documentFilterParams.attributes.docType.value) {
        return Object.keys(getCustAttrsForSelectedDocType()).filter((param) => {
            return !docSortConfig.tableRows.includes(param);
        });
    }
};

const getPagesCount = () => {
    return Math.ceil(filterData.obj.paging.count / 10);
};

const selectPage = (pageNum) => {
    docFilterConfig.currentPage = pageNum;
    props.filterDocumentsData(documentFilterParams);
};
const resetDocVersions = () => {
    documentDetailsConfig.showDocVersions = false;
    documentFilterParams.attributes = docFilterConfig.attributesOld;
    documentFilterParams.currentOnly = true;
    props.filterDocumentsData(documentFilterParams);
};

const getTableRows = () => {
    if (documentDetailsConfig.showPreview === true) {
        return ['name'];
    } else {
        return docSortConfig.tableRows;
    }
};

const getFlexClass = (row) => {
    if (documentTypedParams[row] === 'string' && row !== 'version') {
        return 'flex w-32';
    } else {
        return 'flex-none w-32';
    }
};

const processDocSelection = (documentId, docType, docIndex) => {
    if (docSelectorConfig.enabled) {
        documentDetailsConfig.selectedDocumentIdsWithType[documentId] = docType;
    } else {
        props.selectDocument(documentId, docType, docIndex);
    }
};
const startDocSelection = () => {
    docSelectorConfig.lastSelection = { ...documentDetailsConfig.selectedDocumentIdsWithType };
    documentDetailsConfig.selectedDocumentIdsWithType = [];

    docSelectorConfig.enabled = true;
};

const endDocSelection = () => {
    documentDetailsConfig.selectedDocumentIdsWithType = { ...docSelectorConfig.lastSelection };
    docSelectorConfig.enabled = false;
};

const batchFileDownload = async () => {
    if (
        documentDetailsConfig.selectedDocumentIdsWithType &&
        Object.keys(documentDetailsConfig.selectedDocumentIdsWithType).length > 0
    ) {
        for (let documentId of Object.keys(documentDetailsConfig.selectedDocumentIdsWithType)) {
            const res = await getDocument(
                documentId,
                documentDetailsConfig.selectedDocumentIdsWithType[documentId],
                true,
            );
            if (res.contentBody.content) {
                const url = await getUrlFromBase64(res.contentBody.content);
                downloadFile(url, res.name);
            }
        }
    }
};

const batchFileDeactivate = async () => {
    if (
        documentDetailsConfig.selectedDocumentIdsWithType &&
        Object.keys(documentDetailsConfig.selectedDocumentIdsWithType).length > 0
    ) {
        props.prepareDocsForDeactivate(
            Object.keys(documentDetailsConfig.selectedDocumentIdsWithType),
        );
    }
};

const downloadFile = async (dataUrl, fileName) => {
    const link = document.createElement('a');
    link.href = dataUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    console.log('downloadFile', fileName, dataUrl);
    link.click();
    link.remove();
};
</script>
