<template>
    <TransitionRoot as="template" :show="menuOpen === true">
        <Dialog as="div" class="relative z-7000 xl:hidden select-none" @close="menuOpen = false">
            <TransitionChild
                as="template"
                enter="transition-opacity ease-linear duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div
                    :class="[
                        theme.f('nav', { state: 'brand', platform: 'mobile' }),
                        'fixed inset-0 z-[7001] bg-opacity-75',
                    ]"
                />
            </TransitionChild>

            <div class="fixed inset-0 z-[7002] flex">
                <TransitionChild
                    as="template"
                    enter="transition ease-in-out duration-300 transform"
                    enter-from="-translate-x-full"
                    enter-to="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leave-from="translate-x-0"
                    leave-to="-translate-x-full"
                >
                    <DialogPanel class="relative flex w-full max-w-xl flex-1 flex-col bg-slate-900">
                        <TransitionChild
                            as="template"
                            enter="ease-in-out duration-300"
                            enter-from="opacity-0"
                            enter-to="opacity-100"
                            leave="ease-in-out duration-300"
                            leave-from="opacity-100"
                            leave-to="opacity-0"
                        >
                            <div class="absolute top-0 right-0 -mr-12 pt-2">
                                <button
                                    type="button"
                                    class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none bg-slate-900 focus:ring-2 focus:ring-inset focus:ring-slate-200"
                                    @click="menuOpen = false"
                                >
                                    <span class="sr-only">Close sidebar</span>
                                    <MdiClose
                                        class="block h-6 w-6 text-slate-200"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        </TransitionChild>
                        <div class="h-0 flex-1 overflow-y-auto pt-0 pb-4 bg-slate-700">
                            <nav v-if="loggedUser.id !== '0'" class="mt-2 p-0">
                                <a
                                    v-for="item in navigation.main"
                                    :key="item.name"
                                    :href="item.href"
                                    :class="[
                                        currentRouteMatch(item.matchRoutes, route.name)
                                            ? 'bg-slate-900 text-slate-100'
                                            : 'text-slate-200 hover:bg-slate-600 hover:text-slate-200',
                                        'group flex items-center p-3 text-sm font-medium transition-colors',
                                    ]"
                                >
                                    <component
                                        :is="item.icon"
                                        class="h-5 w-5 shrink-0"
                                        aria-hidden="true"
                                    />
                                    <div class="ml-3 text-sm font-medium">
                                        {{ item.title }}
                                    </div>
                                </a>
                            </nav>
                        </div>

                        <div
                            v-if="loggedUser.id !== '0'"
                            class="flex flex-col flex-shrink-0 bg-slate-900 p-0"
                        >
                            <a
                                v-for="item in navigation.user"
                                :key="item.name"
                                :href="item.href"
                                :class="[
                                    currentRouteMatch(item.matchRoutes, route.name)
                                        ? 'bg-slate-900 text-slate-100'
                                        : 'text-slate-200 hover:bg-slate-900 hover:text-slate-100',
                                    'group flex items-center p-3 text-sm font-medium transition-colors',
                                ]"
                            >
                                <component
                                    :is="item.icon"
                                    class="h-5 w-5 shrink-0"
                                    aria-hidden="true"
                                />
                                <div class="ml-3 text-sm font-medium">
                                    {{ item.title }}
                                </div>
                            </a>

                            <button
                                class="group block w-full flex-shrink-0"
                                @click="logoutSession()"
                            >
                                <div
                                    class="flex items-center text-amber-400 group-hover:text-amber-200 group-hover:bg-slate-900 transition-colors p-3"
                                >
                                    <MdiLogout class="block w-5 h-5" aria-hidden="true" />
                                    <div class="ml-3 text-sm font-medium">
                                        {{ t('Logout') }}
                                    </div>
                                </div>
                            </button>
                        </div>
                    </DialogPanel>
                </TransitionChild>
                <div class="w-14 flex-shrink-0">
                    <!-- Force sidebar to shrink to fit close icon -->
                </div>
            </div>
        </Dialog>
    </TransitionRoot>

    <div
        v-if="loggedUser.id !== '0'"
        class="fixed top-0 z-[6999] w-full h-16 flex items-center justify-between bg-slate-900 xl:hidden select-none"
    >
        <router-link
            :to="{ name: 'dashboard' }"
            class="px-2 h-16 flex-none flex flex-col w-28 items-start justify-center bg-slate-900"
        >
            <BrandLogo css-class="w-28" />
            <span class="text-slate-200 text-xs font-bold text-left uppercase tracking-wide">
                {{ t('appName') }}
            </span>
        </router-link>

        <button
            type="button"
            class="inline-flex h-10 w-10 items-center justify-center focus:outline-none text-slate-200 mr-2"
            @click="menuOpen = true"
        >
            <span class="sr-only">Open sidebar</span>
            <MdiMenu class="h-6 w-6" aria-hidden="true" />
        </button>
    </div>

    <!-- Static sidebar for desktop -->
    <div
        class="hidden xl:fixed xl:inset-x-0 xl:flex xl:w-full xl:items-center xl:justify-between z-7000 h-16 select-none bg-slate-900"
    >
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex-auto w-32 flex">
            <router-link
                :to="{ name: 'dashboard' }"
                class="px-2 h-16 flex-none flex flex-col w-28 items-start justify-center bg-slate-900"
            >
                <BrandLogo css-class="w-28" />
                <span class="text-slate-200 text-xs font-bold text-left uppercase tracking-wide">
                    {{ t('appName') }}
                </span>
            </router-link>
            <nav v-if="loggedUser.id !== '0' && route.name !== 'login'" class="flex gap-0">
                <router-link
                    v-for="item in navigation.main"
                    :key="item.name"
                    :to="{
                        name: item.name,
                        params: item.params || {},
                    }"
                    :class="[
                        currentRouteMatch(item.matchRoutes, route.name)
                            ? 'bg-slate-800 text-slate-200'
                            : 'text-slate-300 hover:bg-slate-800 hover:text-slate-200',
                        'flex gap-1.5 items-center px-3 text-sm font-semibold transition-colors',
                    ]"
                >
                    <component :is="item.icon" class="flex-none h-4 w-4" aria-hidden="true" />
                    <div class="text-sm font-medium grow">
                        {{ item.title }}
                    </div>
                </router-link>
            </nav>
        </div>

        <div v-if="loggedUser.id !== '0'" class="flex gap-0 h-16">
            <router-link
                v-for="item in navigation.user"
                :key="item.name"
                :to="item.href"
                :class="[
                    currentRouteMatch(item.matchRoutes, route.name)
                        ? 'bbg-slate-800 text-slate-100'
                        : 'text-slate-200 hover:bg-slate-800 hover:text-slate-100',
                    'flex gap-1.5 items-center px-3 text-sm font-semibold transition-colors',
                ]"
            >
                <component :is="item.icon" class="flex-none h-5 w-5" aria-hidden="true" />
                <div class="text-sm font-medium grow">
                    {{ item.title }}
                </div>
            </router-link>
            <button
                class="flex gap-1.5 items-center px-3 text-sm text-amber-400 hover:text-amber-200 hover:bg-slate-800 transition-colors"
                @click="logoutSession()"
            >
                <MdiLogout class="flex-none w-5 h-5" aria-hidden="true" />
                <div class="text-sm font-medium grow">
                    {{ t('Logout') }}
                </div>
            </button>
            <div class="hidden flex justify-center items-end h-8 pb-2 select-text">
                <BuildBadge />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { currentRouteMatch } from '../composables/navs';
import { getLoggedUser, logout } from '../composables/useLogin';
//import { getLoggedUser, logout } from '../composables/useLoginMock';
import BrandLogo from './BrandLogoComponent.vue';
import BuildBadge from './BuildBadgeComponent.vue';
import { t } from '../composables/i18n';
import { notify } from 'notiwind';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import {
    MdiAccount,
    MdiClose,
    MdiMenu,
    MdiLogout,
    MdiApplicationCog,
    MdiAccountGroup,
    MdiHome,
    MdiFileDocumentEdit,
    MdiVariable,
    MdiListStatus,
    MdiFolderEdit,
    MdiTag,
} from 'materialdesignicons-vue3/icons/';

defineExpose({
    name: 'NavMainHorizontal',
});

const loggedUser = getLoggedUser(),
    menuOpen = ref(false),
    route = useRoute(),
    router = useRouter();

const navigation = {
    main: [
        {
            title: t('Dashboard'),
            name: 'dashboard',
            href: '/',
            icon: MdiHome,
            matchRoutes: ['dashboard'],
        },
        {
            title: t('Documents'),
            name: 'documents',
            href: '/documents',
            icon: MdiFileDocumentEdit,
            matchRoutes: ['documents', 'document'],
        },
        
        /* {
            title: t('Snippets'),
            name: 'snippets',
            href: '/snippets',
            icon: MdiFileDocumentEdit,
            matchRoutes: ['snippets', 'snippet'],
        }, */
        {
            title: t('Users'),
            name: 'users',
            href: '/users',
            icon: MdiAccountGroup,
            matchRoutes: ['users', 'profile'],
        },
    ],
    user: [
        {
            title: t('MyProfile'),
            name: 'MyProfile',
            href: `/profile/${loggedUser.id}`,
            icon: MdiAccount,
            matchRoutes: ['profile'],
        },
        {
            title: t('Settings'),
            name: 'settings',
            href: '/settings',
            icon: MdiApplicationCog,
            matchRoutes: ['settings'],
        },
    ],
};

const logoutSession = async () => {
    const lg = await logout();
    //console.log('NavMainComponent.vue: logoutSession', lg);
    if (lg !== false) {
        router.push('/login');
    } else {
        notify(
            {
                group: 'error',
                title: 'Error',
                text: 'Logout error., see console for more information',
            },
            5000,
        );
    }
};
</script>
