<template>
    <Headify :data="headMeta" />

    <div class="bg-slate-900 select-none" style="background-image: url('theme/default/lgbg.jpg')">
        <main class="w-full mx-auto flex items-center justify-center min-h-screen">
            <div
                class="max-w-md w-full rounded-md shadow-xl shadow-slate-600/30 border border-slate-800 border-2 m-4"
            >
                <div class="grid grid-cols-2 items-center bg-slate-900 rounded-t-md px-4 py-6">
                    <div>
                        <BrandLogo css-class="w-40" />
                    </div>
                    <div
                        class="text-gray-200 text-base text-right tracking-wider flex flex-col gap-y-px"
                    >
                        <span class="uppercase text-lg font-bold">
                            {{ t('appName') }}
                        </span>
                        <span class="text-gray-200 font-medium">
                            <MdiAccount
                                class="mx-auto inline text-slate-200 w-4 h-4 -mt-1"
                                aria-hidden="true"
                            />
                            {{ t('Login') }}
                        </span>
                    </div>
                </div>
                <form class="space-y-6 px-4 py-6 bg-gray-100" method="POST">
                    <div class="shadow-sm -space-y-px">
                        <div>
                            <label for="email-address" class="sr-only">
                                {{ t('loginPlaceholder') }}
                            </label>
                            <input
                                id="userName"
                                v-model="userName"
                                type="text"
                                name="userName"
                                autocomplete="off"
                                required=""
                                class="appearance-none rounded-md block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                :placeholder="t('loginPlaceholder')"
                                @keyup.enter="sendLogin(userName, password)"
                            />
                        </div>
                    </div>

                    <div class="shadow-sm -space-y-px">
                        <div>
                            <label for="email-address" class="sr-only">
                                {{ t('loginPassword') }}
                            </label>
                            <input
                                id="password"
                                v-model="password"
                                type="password"
                                name="password"
                                autocomplete="off"
                                required=""
                                class="appearance-none rounded-md block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                :placeholder="t('pwdPlaceholder')"
                                @keyup.enter="sendLogin(userName, password)"
                            />
                        </div>
                    </div>

                    <div>
                        <button
                            type="button"
                            :class="`${theme.f('btn', {
                                state: 'brand',
                                disabled: serverStatus.be === false,
                            })} w-full block`"
                            :disabled="serverStatus.be === false"
                            @click="sendLogin(userName, password)"
                        >
                            <MdiLock :class="theme.f('btnIcon')" aria-hidden="true" />
                            <span>{{ t('buttonLogin') }}</span>
                        </button>
                    </div>
                </form>

                <div class="flex items-cente justify-between bg-gray-100 px-4 py-4 rounded-b-md">
                    <a
                        href="#"
                        class="flex-none text-sm font-medium text-sky-600 hover:text-sky-500"
                    >
                        {{ t('cantLogin') }}
                    </a>
                    <div class="text-xs pl-4 flex gap-4">
                        <div
                            v-tippy="`${serverStatus.beNote} (${keeper('apiUrl')})`"
                            v-clipboard:copy="copyText.be"
                            v-clipboard:success="copyHandlerSuccess"
                            v-clipboard:error="copyHandlerError"
                            class="font-medium cursor-help py-1 px-3 bg-slate-700 text-gray-300 rounded-md"
                            :title="t('copyToClipboard')"
                        >
                            <MdiServerNetwork
                                :class="[
                                    serverStatus.be !== false ? 'text-emerald-400' : 'text-red-400',
                                    'w-3 h-3 -mt-1 mr-1 inline',
                                ]"
                            />
                            BE
                        </div>
                    </div>
                </div>

                <Spinner
                    v-if="saving.active"
                    type="dots"
                    :color="`text-blue-600`"
                    :color2="`text-blue-400`"
                    :text-color="`text-gray-200`"
                    :overlay="true"
                    :speed="1000"
                    :dots="true"
                    :dot-char="'.'"
                    :dot-dual="false"
                    :dot-reverse="false"
                    :text="saving.text"
                    :inline="false"
                    :pulse="true"
                />
            </div>
        </main>
    </div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Headify from '../components/HeadifyComponent.vue';
import Spinner from '../components/SpinnerComponent.vue';
import navs from '../composables/navs';
import BrandLogo from '../components/BrandLogoComponent.vue';
//import { login, getLoggedUser } from '../composables/useLoginMock';
import { login, getLoggedUser } from '../composables/useLogin';
import { pingBackendServer } from '../composables/useStats';
import { keeper } from '../composables/utils';
import { notify } from 'notiwind';
import { t } from '../composables/i18n';
import { useGlobals } from '../main.js';

import { MdiAccount, MdiLock, MdiServerNetwork } from 'materialdesignicons-vue3/icons/';

const globals = useGlobals();
const userName = ref('');
const password = ref('');
const route = useRoute();
const router = useRouter();
const headMeta = navs().metaFind(route.name);

const saving = reactive({
    active: false,
    text: `${t('loading')}`,
});

const copyText = reactive({
    be: globals.config.env.apiUrl
});

const copyHandlerSuccess = async (capture) => {
    notify(
        {
            group: 'info',
            title: t('copiedToClipboard'),
            text: capture.text,
        },
        1770,
    );
};
const copyHandlerError = async (capture) => {
    notify(
        {
            group: 'error',
            title: t('Error'),
            text: capture.text,
        },
        2500,
    );
};

const serverStatus = reactive({
    be: false,
    beNote: t('serverFail', { serverName: t('backendServerNote') }),
  
});

const serverCheck = async () => {
    serverStatus.be = await pingBackendServer();
    if (serverStatus.be !== false) {
        serverStatus.beNote = t('serverOk', { serverName: t('backendServerNote') });
    }
   
};

const sendLogin = async (userName, password) => {
    if (serverStatus.be !== false) {
        saving.active = true;
        await login(userName, password);
        const loggedUser = getLoggedUser();
        //console.log('LoginView.vue: sendLogin', loggedUser);

        if (loggedUser.role !== 'NONE') {
            saving.active = false;
            notify(
                {
                    group: 'success',
                    title: 'Done',
                    text: `Logged in as ${loggedUser.userName}`,
                },
                2500,
            );
            router.push(keeper('returnUrl') || { name: 'dashboard' });
        } else {
            // Notify problem
            notify(
                {
                    group: 'error',
                    title: 'Error',
                    text: 'Error logging in',
                },
                5000,
            );
            saving.active = false;
        }
    }
};

await serverCheck();
</script>
