const conditionMapping = {
    equals: 'EQUALS',
    after: 'AFTER',
    before: 'BEFORE',
};

const stringConditionMapping = {
    contains: 'CONTAINS',
    equals: 'EQUALS',
    starts: 'STARTS',
    ends: 'ENDS',
};

export const getConditions = () => {
    return Object.keys(conditionMapping);
};

export const getKeywordForCondition = (condition) => {
    return conditionMapping[condition];
};

export const getStringConditions = () => {
    return Object.keys(stringConditionMapping);
};

export const getKeywordForStringCondition = (condition) => {
    return stringConditionMapping[condition];
};

export const useFilter = () => {
    return {
        getConditions: getConditions,
        getKeywordForCondition: getKeywordForCondition,
    };
};

export default useFilter;
