export const getInputType = (paramType) => {
    if (paramType === 'boolean') {
        return 'checkbox';
    } else {
        return 'text';
    }
};

export const docUtils = () => {
    return {
        getInputType: getInputType,
    };
};

export default docUtils;
