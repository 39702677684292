<template>
    <Headify :data="headMeta" />

    <div class="bg-gray-100">
        <NavMainHorizontal />

        <div class="flex flex-1 flex-col h-screen pt-16">
            <main class="flex-1">
                <div class="w-full mx-auto max-w-7xl px-2 sm:px-4 pt-14 md:pt-4">
                    <div
                        class="p-6 bg-slate-900 rounded-md shadow text-center select-none cursor-default bg-bottom"
                        style="background-image: url('/theme/default/lgbg.jpg')"
                    >
                        <div class="text-xl font-medium text-slate-300 py-3 px-2">
                            {{ t('welcomeText') }}
                        </div>
                        <h1
                            class="text-3xl font-bold text-slate-200 uppercase py-3 px-2 tracking-wide"
                        >
                            {{ t('appName') }}
                        </h1>
                    </div>

                    <dl class="mt-5 grid grid-cols-1 gap-6 sm:grid-cols-2">
                        <div class="shadow rounded-md bg-white order-2 md:order-1">
                            <dt
                                class="flex bg-gray-200 font-medium rounded-t-md select-none cursor-default"
                            >
                                <div class="rounded-md p-3 text-gray-600">
                                    <MdiServerNetwork class="h-5 w-5 -mt-1 inline text-blue-600" />
                                    {{ stats[0].name }}
                                </div>
                            </dt>
                            <dd class="min-h-[120px] py-px px-6">
                                <div class="flex items-center my-1">
                                    <div
                                        v-clipboard:copy="copyText.be"
                                        v-clipboard:success="copyHandlerSuccess"
                                        v-clipboard:error="copyHandlerError"
                                        class="p-1 text-sm cursor-help"
                                        :title="t('copyToClipboard')"
                                    >
                                        <span class="text-slate-800 font-medium">
                                            <MdiWeb class="h-5 w-5 -mt-1 inline text-emerald-700" />
                                            {{ config.env.apiUrl }}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    v-for="(stat, index) in stats[0].data"
                                    :key="index"
                                    class="flex items-center my-1"
                                >
                                    <div class="p-1 text-sm">
                                        <span class="text-emerald-700 font-normal">
                                            {{ index }}:
                                        </span>
                                        <span class="text-slate-800 font-medium">{{ stat }}</span>
                                    </div>
                                </div>
                            </dd>
                        </div>
                        <div class="shadow rounded-md bg-white order-1 md:order-2">
                            <dt
                                class="flex bg-gray-200 font-medium rounded-t-md select-none cursor-default"
                            >
                                <div class="rounded-md p-3 text-gray-600">
                                    <MdiFileDocument
                                        class="h-5 w-5 -mt-1 inline text-emerald-600"
                                    />
                                    {{ stats[1].name }}
                                </div>
                            </dt>
                            <dd class="flex items-baseline min-h-[120px] py-px px-6">
                                <Doughnut
                                    :data="stats[1].data.chart"
                                    :options="stats[1].data.options"
                                    class="mt-2"
                                />
                            </dd>
                        </div>
                    </dl>
                </div>
            </main>
        </div>
    </div>
</template>

<script setup>
import { reactive } from 'vue';
import { useRoute } from 'vue-router';
import { t } from '../composables/i18n';
import Headify from '../components/HeadifyComponent.vue';
//import Breadcrumbs from '../components/BreadcrumbsComponent.vue';
import NavMain from '../components/NavMainComponent.vue';
import navs from '../composables/navs';
import { getBackendServerInfo } from '../composables/useStats';
import { listDocuments } from '../composables/useDocuments';

import { useGlobals } from '../main.js';
import { notify } from 'notiwind';
import {
    Chart as ChartJS,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
} from 'chart.js';
import { Bar, Doughnut } from 'vue-chartjs';
import {
    MdiFileDocument,
    MdiServerNetwork,
    MdiVariable,
    MdiWeb,
} from 'materialdesignicons-vue3/icons/';
import NavMainHorizontal from '../components/NavMainHorizontalComponent.vue';

const globals = useGlobals();
const route = useRoute();
ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const stats = reactive([]);
const sources = reactive({
    backend: (await getBackendServerInfo()).build,
    documents: (await listDocuments({ filter: [], paging: {} })).documents,
});

const label = (tooltipItems) => {
    return ` ${t('items', { count: tooltipItems.parsed })} `;
};

const innerLabel = {
    id: 'innerlabel',
    //afterDatasetDraw(chart, args, pluginOptions) {
    afterDatasetDraw(chart, args) {
        if (args.meta.type === 'doughnut') {
            const { ctx } = chart;
            const xa = args.meta.data[0].x;
            const ya = args.meta.data[0].y;
            const middleText = sources.documents.length;
            const fontSize = 36;
            ctx.save();
            ctx.textAlign = 'center';
            ctx.font = `${fontSize}px sans-serif`;
            ctx.fillText(middleText, xa, ya + fontSize / 4);
            ctx.restore();
        }
    },
};

ChartJS.register(innerLabel);

const calculateTemplateStats = (documents) => {
    const calc = countDocTypes(documents);
    const result = {
        chart: {
            labels: ['INVOICE', 'ORDER', 'CONTRACT', 'INBOX'],
            datasets: [
                {
                    backgroundColor: [
                        'rgb(244 63 94)',
                        'rgb(59 130 246)',
                        'rgb(245 158 11)',
                        'rgb(16 185 129)',
                    ],
                    data: Object.values(calc),
                },
            ],
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'bottom',
                },
                tooltip: {
                    callbacks: {
                        label: label,
                    },
                },
            },
        },
    };

    return result;
};

const countDocTypes = (documents) => {
    const types = {
        INVOICE: 0,
        ORDER: 0,
        CONTRACT: 0,
        STATEMENT: 0,
    };

    documents.map((doc) => {
        if (Object.keys(types).includes(doc.docType)) {
            types[doc.docType] += 1;
        }
    });

    return types;
};

const getStats = async () => {
    stats.push({
        name: t('backendServerNote'),
        data: sources.backend,
    });
    stats.push({
        name: t('documentsStatsNote'),
        data: calculateTemplateStats(sources.documents),
    });
};

await getStats();

const copyText = reactive({
    be: globals.config.env.apiUrl,
});

const copyHandlerSuccess = async (capture) => {
    notify(
        {
            group: 'info',
            title: t('copiedToClipboard'),
            text: capture.text,
        },
        1770,
    );
};
const copyHandlerError = async (capture) => {
    notify(
        {
            group: 'error',
            title: t('Error'),
            text: capture.text,
        },
        2500,
    );
};

const headMeta = navs().metaFind(route.name);
</script>
